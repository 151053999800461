@media #{$md-mobile-media} {
  .md-toolbar ~ .md-list--drawer {
    padding-top: 0px;
  }
}
.nav-footer {
  padding-left: $md-default-padding;
  a,
  a:active {
    @extend .md-cell;
    text-decoration: none;
    color: $md-primary-color;
    width: auto;
    margin-left: 0;
  }
}

@media #{$md-desktop-media} {
  .md-toolbar ~ .md-list--drawer {
    padding-top: 0px;
  }
  .drawer {
    .md-toolbar-relative {
      margin-top: 0px;
    }
  }
}

.hf-host-welcome {
  margin-top: 8px;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 8px;
  padding: 8px;
  border: $md-grey-100 1px solid;
  background-color: $md-grey-100;
  border-radius: 2px;
  user-select: auto;
  iframe {
    max-width: 100%;
    &:not([height='575px']) {
      height: auto;
    }
  }
}

@media screen {
  .hf-drawer-guest {
    min-width: 380px;
  }

  .hf-drawer-guest:not(.md-transition--deceleration) + #main-desktop {
    margin-left: 380px;
  }
}
