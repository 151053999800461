.recommendation-view {
  .ashtml-span {
    iframe {
      max-width: 100%;
      height: 200px;
    }
  }
  .md-card-text h3 {
    white-space: normal;
  }
}

.share-input {
  border: 1px solid #b3b3b3;
  background-color: #fff;
  color: #000;
  font-size: 17px;
  line-height: 18px;
  padding: 6px;
  width: 100%;
}
.share-link {
  padding-top: 16px;
}

.hf-hours-expansion-label {
  font-size: 14px;
}
.hf-hour-label {
  font-size: 14px;
  font-weight: bold;
  color: $md-primary-color;
  padding: 2px 0;
}
.hf-hour-value {
  padding: 2px 0;
}
