#hf-search {
  cursor: pointer;
  margin: auto 8px;

  .material-icons {
    line-height: 38px;
    font-size: 28px;
    vertical-align: bottom;
  }

  .searchEntry {
    width: auto;
    display: inline-block;
    margin: 0 12px;
  }
}

.search-dialog {
  #search-results {
    width: 90%;
    min-width: 300px;
    max-width: 550px;
    .md-dialog-content--padded {
      padding: 0;
    }
  }
  .search-results > .md-card {
    margin-top: 0;
  }
}
