#map-outer-container {
  position: relative;
}
.map-holder-fixed {
  position: fixed !important;
}
.hf-mainview-map-overlay {
  position: fixed;
  top: 64px;
  left: 50vw;
  width: 50vw;
  height: calc(100vh - 64px);
  background: rgba(0, 0, 0, 0.2);
  &.nav-open {
    width: calc(50vw - 160px);
    left: calc(50vw + 160px);
  }
}
.hf-mapview-map-overlay {
  position: fixed;
  top: 56px;
  left: 0;
  width: 100vw;
  height: calc(100vh - 56px);
  background: rgba(0, 0, 0, 0.2);
}
.hf-map-popup {
  width: 320px;
  height: 138px;
  position: absolute;
  cursor: pointer;
  top: calc(50% - 69px);
  left: calc(50% - 160px);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
}

.hf-map-popup-content {
  text-align: center;
  background-color: #fff;
  height: 100%;
  position: relative;
}

.hf-map-popup-title {
  height: 40px;
  overflow: hidden;
  font-size: 18px;
  line-height: 20px;
  color: $md-primary-color;
  margin-bottom: 8px;
}
.hf-map-why-recommend {
  height: 14px;
  overflow: hidden;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hf-map-address {
  margin-bottom: 8px;
  .hf-map-address-line {
    height: 14px;
    overflow: hidden;
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.hf-map-popup-close {
  position: absolute;
  top: 0;
  right: 0;
  i {
    font-size: 18px;
  }
}

.guidebook-map {
  .map-toolbar {
    position: absolute;
    z-index: 1;
    top: $md-toolbar-mobile-portrait-height;
    margin-left: 5vw;
    padding-left: 0px;
    width: 90vw;
    background-color: transparent;
    .md-text-field {
      font-size: 20px;
    }
    .md-select-field--btn {
      background-color: white;
    }
  }

  @media #{$md-mobile-media} {
    .map-container-full {
      height: calc(
        100vh - #{1+ $md-toolbar-mobile-portrait-height + $md-bottom-navigation-height +
          $md-tab-icon-height}
      );
      &.recommendation-map {
        height: calc(
          100vh - #{$md-toolbar-mobile-portrait-height +
            $md-bottom-navigation-height}
        );
      }
    }
  }
  @media #{$md-tablet-media} {
    .map-container {
      height: 40vh;
    }
    .map-container-fixed {
      height: calc(100vh - #{$md-toolbar-tablet-height});
    }
  }
  @media #{$md-desktop-media} {
    .map-container-fixed {
      height: calc(100vh - #{$md-toolbar-tablet-height});
    }
    .map-container-full,
    .map-container-full.recommendation-map {
      height: calc(100vh - #{$md-toolbar-tablet-height});
    }
  }
  @media screen and (min-width: 1500px) {
    .map-container-full,
    .map-container-full.recommendation-map {
      width: calc(100vw - 380px);
    }
  }
}
