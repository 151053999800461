#hf-icon-chooser {
  .hf-icon-chooser {
    background-color: #ff5722;
    border-color: #ff5722;
  }
  //center avatar inside ink-container
  .md-tile-addon--avatar {
    display: block;
    margin: auto;
  }
  .md-list-tile {
    padding-left: 0px;
    padding-right: 0px;
  }
  //Hide primary text (required on list item) in icon-chooser lists
  .md-tile-content.md-tile-content--left-avatar {
    display: none;
  }
}
