.house-manual {
  background-color: $md-primary-color;
  .house-manual-title {
    color: white;
  }
}
section.icon-template {
  position: relative;
}
.icon-template {
  h2.md-display-1 {
    font-size: 24px;
    width: 70%;
    word-wrap: normal;
    overflow: hidden;
    display: inline-block;
  }
  span {
    height: 100%;
    position: absolute;
    width: 30%;
    right: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    svg {
      display: block;
      margin: auto;
    }
  }
}
@media screen and (min-width: 1024px) and (max-width: 1160px) {
  .icon-template {
    span {
      .hf-lge-icon.md-avatar {
        width: 48px;
        height: 48px;
      }
    }
  }
}

.weatherContainer {
  max-width: calc(100vw - 32px);
  overflow-x: hidden;
}

//@value colors: "styles/colors.css";
//@value green from colors;

.left-sheet {
  background-color: white;
  border: solid 1px #dfdfdf;
  height: auto;
  img {
    background-color: #dfdfdf;
  }
}
.right-sheet {
  background-color: white;
  height: auto;
}
.map-sheet {
  height: 400px;
  background-color: #80d8ff;
}
@media (max-width: 600px - 1) {
  .map-sheet {
    height: 200px;
    background-color: #80d8ff;
  }
}

.md-card {
  margin-top: 16px;
}

.house-sheet {
  height: auto;
  background-color: white;
}
.recommendation-sheet {
  height: auto;
  background-color: white;
}
.title-sheet {
  position: relative;
  padding-top: $hf-title-padding;
  padding-left: 16px;
  border-bottom: solid 1px #dfdfdf;
}
.col1 {
  height: 600px;
  background-color: white;
}
.arrival-card {
  height: auto;
}
.arrival-card section.md-card-text,
.list-item-modal-content {
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: normal;
  }
}

.home-info-card {
  height: 100px;
}
//reduce padding on sponsored recs cards (which have a 2nd title)
.md-card-title:last-child {
  padding-bottom: 8px;
}
.col-2 {
  height: 600px;
  background-color: white;
}
.dummy-content {
  background-color: #dfdfdf;
}
.footer-content {
  height: 72px;
}
.mozio-dialog {
  width: 80%;
  height: 80%;
}
.avatar-transparent-bg {
  background-color: white;
  border: none;
}
.md-avatar {
  border: none;
}
.rightdrawer-tile {
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  .md-tile-content--left-icon {
    padding-left: 24px;
  }
  min-width: 280px;
}
.rightdrawer-icon {
  width: 16px;
  height: 16px;
}

.recommendationTabs {
  .md-icon.material-icons {
    @extend .md-icon--tab;
  }
}
.hf-disabled-input {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: $md-grey-100;
}
.hf-scrolling-tabs {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

@import 'pages/Print/Print.scss';
@import 'pages/MainView/MainView.scss';
@import 'pages/Recommendation/recommendation.scss';

/*Fix inputs on very small devices - like apple watches or tiny phones*/
@media screen and (max-width: 319px) {
  .hf-ground-transport {
    .md-text-field-icon-container,
    .md-select-field {
      height: 60px;
    }
  }
  ul.hfTabs > li.md-tab--active,
  ul.hfTabs > li.md-tab:hover {
    width: 48px;
  }
  .md-toolbar--fixed {
    position: relative;
    padding: 4px;
  }
  .md-grid {
    margin-bottom: 48px;
  }
}
