.hf-drawer {
  background-color: $md-theme-blue;

  header > .md-cell--right > button > i {
    color: $md-white-base;
  }
}

@media #{$md-mobile-media} {
  .md-toolbar ~ .md-list--drawer {
    padding-top: 0px;
  }
}
.nav-footer {
  padding-left: $md-default-padding;
  a,
  a:active {
    @extend .md-cell;
    text-decoration: none;
    color: $md-primary-color;
    width: auto;
    margin-left: 0;
  }
}

@media #{$md-desktop-media} {
  .md-toolbar ~ .md-list--drawer {
    padding-top: 0px;
  }
  .hf-drawer {
    .md-toolbar-relative {
      margin-top: 0px;
    }
  }
}

.hf-icon-color {
  color: $md-grey-700;
}

#guidebooks_icon .hf-icon-color {
  color: $md-secondary-color;
}
#hostintros_icon .hf-icon-color {
  color: $md-theme-yellow;
}
#marketplace_icon .hf-icon-color {
  color: $md-primary-color;
}
#informations_icon .hf-icon-color {
  color: $md-theme-blue;
}
#recommendations_icon .hf-icon-color {
  color: $md-theme-orange;
}

.md-list-item--nested-container {
  .md-tile-content .md-text--theme-primary {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    font-size: 14px;
  }
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);

  .md-tile-content .md-text--theme-primary {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
  .nav-title {
    display: inline-block;
  }
  .nav-upgrade {
    background-color: $md-tertiary-color;
    color: $md-white-base;
    border-radius: 6px;
    padding: 0px 8px;
    font-size: 11px;
    line-height: 19px;
    float: right;
  }
  .upgrade-icon {
    color: $md-white-base;
    font-size: 11px;
    line-height: 19px;
  }
}
