.address-map {
  .hf-map-popup {
    border-radius: 5px;
    border: 1px solid #aaa;
  }

  .hf-map-popup-content {
    text-align: center;
    background-color: #ccc;
    height: 100%;
  }

  .map-toolbar {
    position: absolute;
    z-index: 1;
    top: $md-toolbar-mobile-portrait-height;
    margin-left: 5vw;
    padding-left: 0px;
    width: 90vw;
    background-color: transparent;
    .md-text-field {
      font-size: 20px;
    }
    .md-select-field--btn {
      background-color: white;
    }
  }
  @media #{$md-mobile-media} {
    .map-container-full {
      height: calc(
        100vh - #{1+ $md-toolbar-mobile-portrait-height + $md-bottom-navigation-height +
          $md-tab-icon-height}
      );
    }
  }
  @media #{$md-tablet-media} {
    .map-container {
      height: 40vh;
    }
    .map-container-fixed {
      height: calc(100vh - #{$md-toolbar-tablet-height});
    }
    .map-container-full {
      height: calc(
        100vh - #{$md-toolbar-tablet-height * 3 + $hf-title-padding}
      );
    }
  }
  @media #{$md-desktop-media} {
    .map-container-fixed {
      height: calc(100vh - #{$md-toolbar-tablet-height});
    }
    .map-container-full {
      height: calc(100vh - #{$md-toolbar-tablet-height});
    }
  }
}

@media #{$md-desktop-media} {
  .hf-form-wrapper .address-map {
    height: 100%;
    > div {
      height: 100%;
      .map-container {
        height: 100%;
      }
    }
  }
}
