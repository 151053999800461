@import 'ItemList/List.scss';
@import 'ModelForms/GuidebookEditor/GuidebookEditor.scss';
@import 'ModelForms/GuidebookEditor/GuidebookWizard.scss';
@import '../../../components/Cards/CardTemplates.scss';
@import 'ModelForms/ModelForms.scss';

.hf-hostintroslist-item.md-avatar {
  border: 2px solid $md-orange-500;
  background-color: $md-orange-500;
}

.hf-vertical-divider:nth-child(3n + 2) {
  border-left: 1px $md-grey-100 solid;
  border-right: 1px $md-grey-100 solid;
}
.hf-semi-transparent {
  opacity: 0.4;
}
.hf-welcome {
  background-color: $md-white-base;
  .md-toolbar,
  .md-tabs {
    background-color: #2d2aa5;
    .md-tab-indicator {
      background: #40caa1;
    }
  }
}
h3.yes-wrap {
  white-space: normal;
}
#account-info-form {
  &.md-dialog {
    background-color: #ffffff;
    p {
      margin-bottom: 0;
    }
    h3 {
      color: #2d2aa5;
      white-space: normal;
    }
    // calc(min output size + (max output size - min output size) * (100vw - min screen size) / (max screen size - min screen size));
    width: calc(260px + (600 - 260) * (100vw - 280px) / (1800 - 280));
    max-width: calc(100vw - 20px);
    max-height: calc(100vh - 20px);
  }
}
@media only screen and (max-width: 400px) {
  .md-text-field-message--inactive {
    display: none;
  }
  button {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 300px) {
  #account-info-form .md-floating-label {
    position: relative;
  }
}
.hf-selectall {
  float: right;
}
.hf-card-select-list {
  overflow: auto;
  height: 500px;
  max-height: 500px;
}
.card-selection {
  padding-top: 0px;
}
.hf-stepper {
  .step {
    position: relative;
    min-height: 24px /* circle-size */;
  }
  .step > div:first-child {
    position: static;
    height: 0;
    margin-left: -16px;
  }
  .step > div:last-child {
    margin-left: 36px;
    padding-left: 12px;
  }
  .line {
    position: absolute;
    border-left: 1px solid $md-grey-400;
    left: 4px;
    bottom: 10px;
    top: 50px;
  }
  .step:last-child .line {
    display: none;
  }
  .title {
    vertical-align: middle;
  }
  .stepper-title {
    line-height: 24px;
    font-size: 17px;
  }
  .stepper-subtitle {
    // line-height: 18px;
    // font-size: 14px;
  }
  .hf-step-content {
    margin-top: 16px;
    // background-color: $md-grey-100;
  }
  .hf-step-footer {
    background-color: white;
  }
}
.ql-editor p {
  margin-bottom: 10px;
  font-size: 14px;
}

.hf-place-photos {
  position: relative;
}

.hf-loading-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

.hf-add-theme-form {
  background-color: $md-grey-100;
  margin: 16px 0;
}

.gb-qr-code {
  cursor: pointer;
  z-index: 10000;
  width: 192px;
  margin: 0 auto;
}

.splash-field-data {
  .md-cell {
    padding: 2px;
  }
  .row-header {
    background: $md-light-blue-100;
  }
  .row-even {
    background: $md-grey-200;
  }
  .action-icon {
    position: relative;
    cursor: pointer;
    display: inline-block;
    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
    margin-left: 6px;
  }
}
.token-value {
  color: $md-primary-color;
}

@media only screen and (max-width: 700px) {
  //Make stepper smaller on mobiles and small tablets
  .hf-card-select-list {
    overflow: auto;
    height: 300px;
    max-height: 300px;
  }
  .hf-stepper {
    padding: 0px 16px;
    .md-avatar {
      height: 24px;
      width: 24px;
    }
    .md-avatar-content {
      font-size: 12px;
    }
    .step > div:first-child {
      margin-left: -8px;
    }
    .step > div:last-child {
      margin-left: 24px;
      padding-left: 4px;
    }
    .hf-step-content > .hf-headline-margin > div:first-child {
      margin-top: -24px;
    }
    .line {
      left: 4px;
      bottom: 10px;
      top: 32px;
    }
    .title {
      line-height: 24px;
      font-size: 14px;
    }
  }
}
