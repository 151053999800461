.error-page {
  background-color: #fff;
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;

  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }

  &.nested {
    height: calc(100% - 64px);
    width: auto;
    width: -webkit-fill-available;
    width: -moz-available;

    @media only screen and (max-width: 1280px) {
      height: auto;
    }

    .error-page__left {
      transform: scale(0.8);
      transform-origin: top left;
      min-width: 400px;

      @media only screen and (max-width: 1280px) {
        height: 420px;
      }
    }

    .error-page__right {
      transform: scale(0.8);
      transform-origin: top right;
      min-width: 300px;
    }
  }

  &__left {
    height: 500px;
    min-width: 500px;
    position: relative;

    @media only screen and (max-width: 1280px) {
      align-self: center;
    }
  }

  &__right {
    height: 600px;
    min-width: 300px;
    position: relative;

    @media only screen and (max-width: 1280px) {
      display: none;
    }
  }

  &__background {
    z-index: 0;

    #yellow-bubble {
      position: absolute;
      top: 42px;
      left: 424px;
    }

    #light-blue-bubble {
      position: absolute;
      top: 112px;
      left: 80px;
    }

    #sloth-image {
      position: absolute;
      top: 240px;
      left: 180px;
    }

    #purple-bubble {
      position: absolute;
      top: 356px;
      left: 4px;
    }

    #blue-bubble {
      position: absolute;
      top: 24px;
      right: 220px;
    }

    #red-bubble {
      position: absolute;
      top: 72px;
      right: 0;
    }
  }

  &__container {
    align-self: center;
    display: flex;
    margin: 16px 24px;
    position: relative;
    top: 92px;
    z-index: 2;
    text-wrap: pretty;

    @media only screen and (max-width: 1280px) {
      top: unset;
    }
  }

  &__button {
    border-radius: 24px !important;
    margin-top: 16px !important;
  }
}
