.hf-public-view-button {
  width: 220px;
}

.hf-signup-card {
  min-width: 556px;

  @media only screen and (max-width: 580px) {
    min-width: calc(100% - 24px);
    padding: 24px 16px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 0px;
  }
}

.hf-public-view-welcome {
  display: none;
  max-width: 500px;

  @media only screen and (max-width: 1024px) {
    display: block;
  }

  @media only screen and (min-width: 1400px) {
    display: block;
    max-width: 278px;
  }

  @media only screen and (min-width: 1600px) {
    display: block;
    max-width: 25%;
  }
}
