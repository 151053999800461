@import url('https://fonts.googleapis.com/css?family=Dosis:400,700|Lato:400,700|Old+Standard+TT|Open+Sans:400,700|PT+Serif:400,700|Ubuntu:400,700');
$md-print-text-color: #545454;

.image-loading {
  background: repeating-linear-gradient(
    -45deg,
    #b3e5fc,
    #b3e5fc 5px,
    white 5px,
    white 10px
  );
}

@media all {
  #loader {
    display: block;
    margin-top: 100px;
  }

  .hf-host-paper .hf-preview {
    padding-top: 50px;
  }

  #coverpage,
  #coverpage2,
  #coverpage3,
  #coverpage4 {
    margin: 0;
    margin-top: 60px;
    padding: 0;
    h2.hf-print-gb-title {
      font-size: 42px;
      text-transform: uppercase;
      color: #0288d1;
      padding-top: 40px;
      padding-bottom: 50px;
      font-weight: 700;
    }
    .header-url {
      max-width: 250px;
      float: right;
    }
  }

  .blurb-advert {
    cursor: pointer;
  }

  //Allow for coverpage 4 to be printed edge to edge
  #coverpage4 {
    margin-left: 0px;
    padding-left: 0px;
    #cp4-svg {
      margin-left: -24px;
    }
  }

  .hf-gb-url {
    text-align: center;
    color: #0288d1;
    margin-top: 60px;
  }

  //Coverpage 2 - Travel images shown on coverpage 2 are behind property image, cannot use absolute positioning as messes with print rendered in plugin
  img.hf-cp2-background {
    width: 100%;
  }

  div.hf-cp2-property {
    margin-top: -400px;
    margin-bottom: 200px;
  }

  img.hf-cp2-property {
    width: 300px;
    height: 300px;
    display: inline-block;
    margin-left: 225px;
  }

  .hf-icon-table {
    display: block;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    tbody,
    tr {
      display: block;
    }
    .hf-col {
      display: inline-block;
      width: 33.333333%;
    }
    .hf-col-sm {
      padding: 2%;
      display: inline-block;
      width: 11.1111111%;
    }
  }

  .hf-print-host-name {
    font-size: larger;
    font-weight: 700;
  }
  .hf-informationslist-item div.md-avatar.hf-host-icon {
    border: none;
    background-color: $md-red-900;
  }

  .hf-qr-code {
    position: absolute;
    right: 25px;
    cursor: pointer;
    z-index: 100;
  }

  .header-qr-code {
    cursor: pointer;
    margin-top: 32px;
    z-index: 10000;
  }

  div.hf-print-active.selected::after {
    content: '';
    border-radius: 50%;
    background-color: #fff;
    background-image: url(https://storage.googleapis.com/hostfully-wp-1/hf-check-circle-blue.svg);
    background-size: contain;
    background-position: top right;
    position: absolute;
    right: 0px;
    bottom: 0px;
    height: 44px;
    width: 44px;
  }

  #print-preview {
    margin-left: auto;
    margin-right: auto;
  }

  #hf-print {
    * {
      font-family: 'Lato', sans-serif;
      letter-spacing: -0.2px;
      line-height: 1.5;
      overflow-wrap: break-word;
    }

    div {
      color: $md-print-text-color;
    }

    h1,
    h2,
    h3,
    p,
    div {
      color: $md-print-text-color;
      font-family: 'Lato', sans-serif;
      letter-spacing: -0.2px;
      overflow-wrap: break-word;
    }
    .md-text--secondary {
      color: $md-print-text-color;
    }
    h1,
    h2,
    h3 {
      font-weight: 700;
    }

    a:after {
      content: ' (' attr(href) ') ';
      font-weight: normal;
      text-decoration: none;
      word-wrap: break-word !important;
    }
    a.no-auto-link:after {
      content: '';
    }
    // exceptions to the a:after stuff here
    .guidebook-map,
    .disable-auto-link {
      a:after {
        content: '';
      }
    }

    //manage display of images embeded via quill

    //1. reduce size
    span.ashtml-span {
      p > img {
        max-width: 50%;
        max-height: 300px;
      }
      p > a > img {
        max-width: 50%;
        max-height: 300px;
      }
    }
    .access-image {
      max-width: 600px;
      max-height: 300px;
    }
    //2. allow user to show only thumbnail
    &.hf-print-hide-images {
      span.ashtml-span {
        p > img {
          width: 10%;
        }
        p > a > img {
          width: 10%;
        }
      }
      img.access-image {
        width: 10%;
      }
    }

    img.hf-cp3-property {
      max-width: 100%;
      height: auto;
    }
    img.hf-cp2-property,
    img.hf-cp3-property {
      border-radius: 50%;
      border: 5px solid white;
    }

    .hf-full-width {
      width: 100%;
    }
    img.hf-host-img {
      height: auto;
      width: 80%;
      border-radius: 50%;
      -webkit-border-radius: 50%;
    }

    iframe {
      display: none;
    }

    .hf-title-page-header {
      margin-top: 150px;
      margin-bottom: -200px;
    }
    .hf-printable-link {
      color: $md-light-blue-700;
    }
    .hf-print-title-wrapper {
      display: table;
      width: 100%;
      height: 100px;
      text-align: center;
    }
    .hf-print-gb-title {
      display: table-cell;
      vertical-align: middle;
      color: $md-light-blue-700;
      text-transform: uppercase;
      text-align: center;
    }
    .hf-recs-table {
      table-layout: fixed;
      width: 100%;
      td {
        width: 50%;
        overflow-wrap: break-word;
      }
    }
    .hf-print-rec-title {
      color: $md-light-blue-700;
      text-transform: uppercase;
      text-align: center;
    }

    .print-header-coverpage {
      min-height: 78px;
      position: relative;
      .hf-print-logo {
        max-height: 56px;
        max-width: 200px;
        position: absolute;
        top: 8px;
        left: 0;
      }
      .hf-print-logo-right {
        right: 0px;
        left: auto;
      }
      h2 {
        margin-top: 12px;
      }
    }
    .print-header-cp2 {
      min-height: 38px;
      position: relative;
      .hf-print-logo {
        height: 38px;
        display: block;
        margin: auto;
      }
    }

    .hf-print-map-container-listing {
      height: 520px;
    }
    .hf-print-map-container-full {
      height: 520px;
      //Could not use full page map because of a bug in Chrome print preview which showed overflowing map tiles on subsequent page.
      //height: calc(297mm - 72px);
    }

    .overlay-anchor {
      position: relative;
      height: 200px;
    }
    .hf-map-img-overlay-wrap {
      position: absolute;
      left: 50%;
      margin-left: -130px;
      z-index: 10;
    }
    .map-circle-overlay {
      display: inline-block;
      height: 260px;
      width: 260px;
      border-radius: 50%;
      border: 5px solid white;
      overflow: hidden;
    }
    .hf-map-img-overlay {
      height: 260px;
      width: 260px;
      object-fit: cover;
    }
    .print-host-intro {
      padding-left: 10%;
      padding-right: 10%;
      line-height: 20px;
      max-height: 180px;
      overflow: hidden;
      margin-bottom: 12px;
    }
    .content-title,
    div.content-title > span {
      font-size: 29px;
      text-transform: uppercase;
      color: $md-light-blue-700;
      margin-top: 40px;
      margin-bottom: 20px;
      font-weight: 700;
      &.content-subtitle {
        font-size: 24px;
      }
    }
    .content-block {
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 28px;
      padding-right: 28px;
      margin-bottom: 40px;
    }
    tr {
      vertical-align: top;
    }
    .hf-print-rec-name-why {
      margin-bottom: 8px;
      span {
        line-height: 1.2;
      }
    }
    .contact {
      font-size: 11px;
    }
    .hf-print-rec-name {
      font-weight: 700;
      font-size: 15px;
    }
    .rec-image {
      padding-top: 10px;
      img {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        border-width: 3px;
        border-style: solid;
        border-color: $md-grey-500;
      }
    }
    .print-subsection {
      .print-subheader {
        font-weight: 700;
        margin-top: 20px;
      }
    }
    .hf-print-wifi {
      font-weight: 700;
    }
    .indented {
      padding-left: 40px;
    }
    /* apply quill styles to lists */
    li:not(.md-list-item) {
      &.ql-indent-1 {
        margin-left: 2.5em;
      }
      &.ql-indent-2 {
        margin-left: 5em;
      }
      &.ql-indent-3 {
        margin-left: 7.5em;
      }
      &.ql-indent-4 {
        margin-left: 10em;
      }
      &.ql-indent-5 {
        margin-left: 12.5em;
      }
      &.ql-indent-6 {
        margin-left: 15em;
      }
      &.ql-indent-7 {
        margin-left: 17.5em;
      }
      &.ql-indent-8 {
        margin-left: 20em;
      }
      &.ql-indent-9 {
        margin-left: 22.5em;
      }
    }
    ul > li:not(.md-list-item) {
      line-height: 19px;
      position: relative;
      &:before {
        content: '\2022' ' '; /* The unicode for • character */
      }
    }
    ol > li:not(.md-list-item) {
      line-height: 19px;
      position: relative;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
        list-9;
      counter-increment: list-0;
      &:before {
        content: counter(list-0, decimal) '. ';
        line-height: 19px;
      }
      &.ql-indent-1 {
        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-1;
        &:before {
          content: counter(list-1, lower-alpha) '. ';
        }
      }
      &.ql-indent-2 {
        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-2;
        &:before {
          content: counter(list-2, lower-roman) '. ';
        }
      }
      &.ql-indent-3 {
        counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-3;
        &:before {
          content: counter(list-3, decimal) '. ';
        }
      }
      &.ql-indent-4 {
        counter-reset: list-5 list-6 list-7 list-8 list-9;
        counter-increment: list-4;
        &:before {
          content: counter(list-4, lower-alpha) '. ';
        }
      }
      &.ql-indent-5 {
        counter-reset: list-6 list-7 list-8 list-9;
        counter-increment: list-5;
        &:before {
          content: counter(list-5, lower-roman) '. ';
        }
      }
      &.ql-indent-6 {
        counter-reset: list-7 list-8 list-9;
        counter-increment: list-6;
        &:before {
          content: counter(list-6, decimal) '. ';
        }
      }
      &.ql-indent-7 {
        counter-reset: list-8 list-9;
        counter-increment: list-7;
        &:before {
          content: counter(list-7, lower-alpha) '. ';
        }
      }
      &.ql-indent-8 {
        counter-reset: list-9;
        counter-increment: list-8;
        &:before {
          content: counter(list-8, lower-roman) '. ';
        }
      }
      &.ql-indent-9 {
        counter-increment: list-9;
        &:before {
          content: counter(list-9, decimal) '. ';
        }
      }
    }
    .li-showdot {
      list-style: disc;
    }
    .print-house-rules {
      margin-top: 20px;
    }
    .rec-image-cell {
      padding: 18px 10px 10px;
    }
    .rec-content-cell {
      padding: 10px;
    }
    img.hf-cp4-property {
      outline: $md-light-blue-400 24px solid;
      max-width: 100%;
      max-height: 100%;
      display: block;
    }
    .page-break {
      display: none;
    }
  }
}
.hf-print-container {
  position: relative;
  .title-content-wrapper-avoid {
    .content-title {
      display: block;
      padding-bottom: 20px;
    }
  }
}
.hf-print-config-sheet {
  border-left: $md-grey-100 1px solid;
  position: relative;
  right: 0px;
  width: 30%;
  background-color: $md-light-blue-50;
  height: calc(100vh - 128px);
  overflow-y: scroll;
  display: inline-block;
  float: right;
}
.hf-control-padding {
  margin-top: 16px;
  margin-bottom: -8px;
}
#hf-print {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 24px;
  margin-bottom: 0;
  min-height: 0;
  box-shadow: none;
}

// screen only styles
@media screen {
  #hf-print {
    width: 800px;
    min-width: 800px;
    max-width: 800px;
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    border: $md-grey-200 solid 1px;
    background-color: $md-white-base;
    .page-break {
      display: block;
      margin-top: 100px;
      border-top: $md-light-green-100 dashed 1px;
    }
  }
  .hf-print-preview-sheet {
    background-color: $md-grey-3;
    padding: 8px;
    width: 70%;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    height: calc(100vh - 128px);
    display: inline-block;
    float: left;
  }
  div.hf-cp4-property {
    width: 30%;
    margin-top: -43%;
    margin-bottom: 25%;
    margin-left: 50%;
  }
}

@media screen and (max-width: 640px) {
  .hf-print-config-sheet {
    position: relative;
    width: 100vw;
    display: block;
  }
  .hf-print-preview-sheet {
    position: relative;
    width: 100vw;
  }
}

// print only styles
@media print {
  .md-snackbar {
    display: none;
  }
  //Hide elements we don't want to print
  header.md-toolbar,
  header.md-toolbar--fixed,
  header.hf-host-toolbar.hf-guidebooks,
  .md-drawer,
  .hf-print-config-sheet {
    display: none;
  }

  //prevent map randomly missing a row of tiles, see chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=426294&q=print%20maps&colspec=ID%20Pri%20M%20Stars%20ReleaseBlock%20Component%20Status%20Owner%20Summary%20OS%20Modified
  #rec-map-food,
  #rec-map-bars,
  #rec-map-activities,
  #rec-map-attractions,
  #rec-map-other,
  #rec-map-shopping,
  #rec-map-all {
    div > img {
      position: absolute;
    }
  }

  html,
  body {
    height: 100%;
  }
  a {
    img {
      border: 0;
    }
  }

  img.hf-print-rec-img {
    //prevent chrome displaying images full size
    //max-width: 70%;
    //max-height: 70%;
  }

  .hidden-print {
    display: none !important;
  }

  #hf-print {
    //width: 210mm;
    min-height: calc(297mm - 48px);
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    position: relative;
    padding: 24px 24px 12px;
    color: $md-print-text-color;
    height: 100%;
    box-shadow: none;
    /* prevent autolinking within map */
    .gm-style a:after {
      content: '';
    }
    /* ditch paper shadow */

    .print-header {
      min-height: 78px;
      float: right;
    }
    .page-break {
      display: block;
      break-before: page;
    }
    .page-break-after {
      page-break-after: always;
    }
    .content-block-avoid {
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
    }
    span.ashtml-span {
      display: block; //required to be block so that img page-break-inside rules work
      p > img {
        //manage images added using quill editor
        page-break-before: auto;
        page-break-after: auto;
        page-break-inside: avoid;
        position: relative;
      }
    }
    //avoid orphaned headings (a break in between the heading and content) of a content-block
    .title-content-wrapper-avoid {
      break-inside: avoid;
    }
    //avoid a break inside a table row
    tr {
      page-break-before: auto;
      page-break-after: auto;
      page-break-inside: avoid;
    }
    .gmnoprint img {
      width: auto !important;
      max-width: none !important;
    }
    .gm-style div > img {
      position: absolute;
    }
  }
}
/*dynamic fonts*/
#hf-print-lato {
  @extend #hf-print;
  font-family: 'Lato', sans-serif !important;
  h1,
  h2,
  h3,
  p {
    font-family: 'Lato', sans-serif !important;
  }
}
#hf-print-opensans {
  @extend #hf-print;
  font-family: 'Open Sans', sans-serif !important;
  h1,
  h2,
  h3,
  p {
    font-family: 'Open Sans', sans-serif !important;
  }
}
#hf-print-oldstandardtt {
  @extend #hf-print;
  * {
    font-family: 'Old Standard TT', serif;
  }
  h1,
  h2,
  h3,
  p {
    font-family: 'Old Standard TT', serif;
  }
}
#hf-print-ptserif {
  @extend #hf-print;
  * {
    font-family: 'PT Serif', sans-serif;
  }
  h1,
  h2,
  h3,
  p {
    font-family: 'PT Serif', serif;
  }
}
#hf-print-ubuntu {
  @extend #hf-print;
  * {
    font-family: 'Ubuntu', sans-serif;
  }
  h1,
  h2,
  h3,
  p {
    font-family: 'Ubuntu', sans-serif;
  }
}
#hf-print-dosis {
  @extend #hf-print;
  * {
    font-family: 'Dosis', sans-serif;
  }
  h1,
  h2,
  h3,
  p {
    font-family: 'Dosis', sans-serif;
  }
}
