.heading {
  display: inline;
}
li {
  list-style: none;
}
.hf-host-welcome .ashtml-span p img {
  max-width: 100%;
  height: auto;
}
