.hf-full-height {
  position: relative;
  height: 100vh;
}
.hf-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hf-main-container {
  background-color: $md-grey-3;
  display: flex;
  flex-direction: column;
}

.hf-list-container {
  background-color: $md-white-base;
  height: calc(100% - 64px);
}

.hf-list-wrapper {
  padding: 0px;

  .hf-list-item:last-child {
    margin-bottom: 96px;
  }

  .md-text-field-container {
    margin: 0px 16px;
    width: auto;
  }

  .hf-list-content {
    background-color: $md-grey-3;
    padding: 2px 0px;
  }
}

.hf-host-toolbar {
  background-color: $md-grey-2;
}

.hf-edit-toolbar {
  background-color: $md-grey-2;
  padding: 0px 8px;
}

.hf-guidebooks {
  background-color: $md-secondary-color;

  &-toolbar {
    background-color: $md-secondary-color;
    height: 100%;

    .md-cell--right.md-toolbar--action-right {
      display: flex;
      column-gap: 24px;
      margin-right: 24px;
      flex-wrap: wrap;
    }
  }

  .md-circular-progress-path {
    stroke: $md-primary-color;
  }
}

.hf-hostintros {
  background-color: $md-theme-yellow;
}
.hf-marketplace {
  background-color: $md-primary-color;
}
.hf-informations {
  background-color: $md-theme-blue;
}
.hf-recommendations {
  background-color: $md-theme-orange;
}
.hf-host-paper .hf-paper {
  background-color: $md-white-base;
}

.hf-about-panel {
  background-color: $md-grey-200;
  margin: 16px;
}

.hf-marketplace-about {
  background-color: #e1f6ee;
  margin: 16px;
}

.hf-paper-templates {
  background-color: $md-deep-purple-50;
  margin: 16px;

  .hf-template-wrapper {
    .hf-template-action-cards {
      display: flex;
      column-gap: 16px;
      flex-wrap: wrap;

      .md-card {
        border-radius: 8px;
        position: relative;
        height: 162px;
        width: 162px;
        overflow: hidden;
        cursor: pointer;

        .md-card-text {
          padding: 0px;
          margin: 16px;
          max-height: 40px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          word-wrap: break-word;
        }
      }
    }
  }
  .hf-template-icon {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 16px;
    svg {
      display: block;
      margin: auto;
    }
  }
}
.itemlist-description {
  footer {
    display: none;
  }
  li {
    list-style: disc;
    font-size: 13px;
  }
}

.hf-list-item {
  &--guidebook {
    display: flex;
    align-items: center;
    padding: 10px 48px 10px 24px;
    margin: 16px;
    border-radius: 8px;
    column-gap: 16px;
    cursor: pointer;
    background-color: #fff;

    @media only screen and (max-width: 568px) {
      flex-direction: column;
    }

    &-title {
      flex: 1;

      .md-subheading-1 {
        margin: 0;
      }
    }

    .md-avatar {
      height: 48px;
      width: 48px;
    }
  }

  .md-list-tile--two-lines {
    height: 78px;
    padding-bottom: 8px;
  }

  .md-avatar {
    height: 48px;
    width: 48px;
    border: 2px solid $md-grey-2;
    background-color: $md-grey-2;
  }
}

.hf-guidebookslist-item {
  .md-avatar {
    border: 2px solid $md-secondary-color;
    background-color: $md-secondary-color;
  }
  &.hf-draft-list-item {
    background-color: $md-grey-100;
    .md-text {
      color: rgba(0, 0, 0, 0.6);
    }
    .md-avatar {
      border: 2px solid $md-secondary-color;
      background-color: $md-secondary-color;
    }
  }
}
.hf-hostintroslist-item .md-avatar {
  border: 2px solid $md-theme-yellow;
  background-color: $md-orange-500;
}
.hf-marketplacelist-item .md-avatar {
  border: 2px solid $md-primary-color;
  background-color: $md-primary-color;
}
.hf-tokenslist-item {
  .hf-copy-link {
    color: $md-blue-500;
  }
  .hf-token-enabled {
    .hf-token-time {
      font-weight: 500;
      color: $md-green-600;
    }
  }
  .hf-token-expired {
    .hf-token-time {
      font-weight: 500;
      color: $md-yellow-600;
    }
  }
  .hf-token-disabled {
    .hf-token-time {
      font-weight: 500;
      color: $md-grey-400;
    }
  }
}

.hf-informationslist-item .md-avatar {
  border: 2px solid $md-theme-blue;
  background-color: $md-theme-blue;
}

.item-list-secure {
  background-color: #ffffff;
  color: $md-blue-grey-800;
  border-color: $md-blue-grey-800;
  border-width: 1px;
  border-style: solid;
  padding: 1px 6px;
  border-radius: 5px;
  i {
    font-size: 15px;
    vertical-align: text-bottom;
    color: #37474f;
  }
}
.item-list-sponsored {
  background-color: #ffffff;
  color: $md-green-800;
  border-color: $md-green-800;
  border-width: 1px;
  border-style: solid;
  padding: 1px 6px;
  border-radius: 5px;
  i {
    font-size: 15px;
    vertical-align: text-bottom;
    color: $md-green-800;
  }
}
.hf-recommendationslist-item .md-avatar,
.hf-listingslist-item .md-avatar {
  border: 2px solid $md-deep-purple-500;
  background-color: $md-deep-purple-500;
}
.hf-recommendationslist-item .rec-list-category,
.hf-listingslist-item .listing-list-category {
  color: $md-deep-purple-500;
}
.hf-categorieslist-item .cat-type-rec {
  background-color: $md-deep-purple-500;
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 5px;
}
.hf-categorieslist-item .cat-type-hm {
  background-color: $md-purple-500;
  color: #ffffff;
  padding: 2px 6px;
  border-radius: 5px;
}
.hf-blank-list-item .md-avatar {
  border: 2px solid $md-grey-200;
  background-color: $md-grey-200;
  color: $md-grey-500;
}
.hf-no-top-padding {
  padding-top: 0px;
}
.hf-loading {
  animation: fadein 4s cubic-bezier(0.4, 0, 0.2, 1);
}
.hf-upgrade_guidebooks {
  background-color: $md-white-base;
}

.hf-bs-upgrade {
  background-color: $md-red-500;
  p {
    color: $md-white-base;
  }
}
.hf-list-tabs {
  .md-tabs-content {
    background: $md-grey-100;

    .react-swipeable-view-container > div {
      height: 100% !important;
    }
  }
}

@keyframes fadein {
  0% {
    height: 0;
    width: 0;
  }
  10% {
    height: 25%;
    width: 100%;
  }
  50% {
    height: 50%;
    width: 100%;
  }
  100% {
    height: 100%;
    width: 100%;
  }
}

/*Media queries to remove additional padding on phone*/
@media only screen and (max-width: 766px) {
  div.md-grid.hf-host-paper,
  div.md-cell.hf-paper {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
  }
}
