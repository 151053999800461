.hf-get-started {
  display: flex;
  flex-direction: column-reverse;
  column-gap: 24px;

  @media #{$md-desktop-media} {
    flex-direction: row;
    row-gap: 24px;
    justify-content: space-around;
    height: calc(100vh - $md-toolbar-tablet-height);
  }

  &-background {
    position: absolute;
    width: 100%;
    height: calc(100vh - $md-toolbar-tablet-height);
    background: url('/images/bg.jpg') lightgray 50% / cover no-repeat;
    filter: blur(125px);
    z-index: 0;
  }

  &-left {
    flex: 1;
    flex-direction: column;
    z-index: 2;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    margin: 24px;
    padding-left: 48px;

    &__title {
      color: $md-theme-blue;
      font-size: 56px;
      line-height: 120%;
    }

    &__subtitle-1 {
      color: $md-secondary-color;
      font-size: 32px;
      line-height: 120%;
    }

    &__subtitle-2 {
      color: $md-grey-2;
      font-size: 32px;
      line-height: 120%;
    }

    &__btn {
      border-radius: 20px;
      margin-top: 48px;
      font-size: 18px;
      height: auto;
    }
  }

  &-right {
    align-self: center;
    display: flex;
    margin: 24px;
    position: relative;
    justify-content: center;
    align-items: center;
    flex: 1.25;
    max-width: 600px;
    z-index: 2;

    img {
      width: 100%;
    }

    @media #{$md-desktop-media} {
      max-width: unset;
    }

    &__tablet {
      max-width: 75% !important;
      position: absolute;
    }
  }
}
