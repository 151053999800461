#print-configure {
  padding: 16px;
}

.hf-host-modal .md-paper {
  background-color: rgba(250, 250, 250, 0.99);
  header.md-paper.md-paper--2 {
    background-color: transparent;
    box-shadow: none;
    .md-toolbar--action-left,
    .md-title--toolbar {
      display: none;
    }
  }
  h1,
  h2 {
    color: $md-primary-color;
  }
  h2 {
    margin-top: 20px;
  }
  p {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.hf-help-screenshot {
  padding-top: 20px;
  padding-bottom: 20px;
}
