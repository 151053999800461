.splash-screen {
  h3 {
    font-size: 16px;
    line-height: 22px;
    margin: 24px 0;
    white-space: normal;
  }
  /* general slick slider css overrides */
  .slick-slider {
    background-color: $md-grey-3;
    border-radius: 12px;
  }

  .term-content {
    margin: 16px;
    padding: 12px 0;
    border-style: solid;
    border-width: 1px;
    border-radius: 12px;
    iframe {
      width: 342px;
      height: 193px;
    }
  }
  .slick-dots li button {
    pointer-events: none;
  }

  .input-description {
    margin: 16px;
    padding: 12px 0;
  }

  .splash-logo {
    height: 44px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 16px auto 8px;
    &.cover {
      height: 60px;
      margin: 16px auto;
    }
  }

  .splash-button {
    margin: 12px 0;
    font-size: 16px;
    height: auto;
  }
}

.splash-screen.desktop {
  width: 100vw;
  height: 100vh;
  background: #bdbdbd;
  overflow: auto;

  .slick-slider {
    margin: 40px auto;
    width: 400px;
    height: auto;
  }

  .slick-slide {
    position: relative;
  }

  .input-description {
    margin: 16px 16px;
  }

  .illustration-container {
    height: 400px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    overflow: hidden;

    &.flexible {
      height: auto;
    }
    .term-content-inner {
      padding: 0 12px;
      overflow: auto;
      height: 400px;
    }
    .page-three-header {
      height: 138px;
      overflow: hidden;
    }
  }
  .copy-container {
    text-align: center;
    padding: 18px;
  }
  .page-three .copy-container {
    height: calc(614px - 138px);
    overflow: auto;
  }
}

/* portrait */
@media screen and (max-aspect-ratio: 13/9) {
  /* portrait-specific styles */
  .splash-screen.tablet {
    .slick-slide {
      height: calc(100vh - 40px);
    }
    .illustration-container {
      height: 80vw;
      width: 80vw;
      margin-left: 10vw;
      text-align: center;
      &.flexible {
        height: auto;
      }
      .term-content iframe {
        // width, minus margins and borders and padding
        width: calc(80vw - 32px - 2px - 24px);
        height: calc(calc(80vw - 32px - 2px - 24px) / 16 * 9);
      }
      .term-content-inner {
        // 100 - space for logo, button, nav
        height: calc(100vh - 220px);
        padding: 0 12px;
        overflow: auto;
      }
      .page-three-header {
        height: 138px;
        overflow: hidden;
      }
    }
    .copy-container {
      // total height minus 80% width, with room for dots (40px)
      height: calc(100vh - 80vw - 40px);
      width: 80vw;
      margin-left: 10vw;
      text-align: center;
      overflow: hidden;
      &.flexible {
        height: auto;
      }
    }
    .page-three .copy-container.flexible {
      height: calc(100vh - 138px - 40px);
      overflow: auto;
      .input-form {
        margin-bottom: 40px;
      }
    }
  }
  .splash-screen.mobile {
    .slick-slide {
      height: calc(100vh - 74px);
    }
    .illustration-container {
      height: 100vw;
      width: 100vw;
      &.flexible {
        height: auto;
      }
      .term-content iframe {
        // width, minus margins and borders and padding
        width: calc(100vw - 32px - 2px - 24px);
        height: calc(calc(100vw - 32px - 2px - 24px) / 16 * 9);
      }
      .term-content-inner {
        // 100 - space for logo, button, nav
        height: calc(100vh - 254px);
        padding: 0 12px;
        overflow: auto;
      }
      .page-three-header {
        height: 138px;
        overflow: hidden;
      }
    }
    .copy-container {
      // total height minus 100% width, with room for dots (40px)
      height: calc(100vh - 100vw - 40px);
      width: 100vw;
      text-align: center;
      overflow: hidden;
      &.flexible {
        height: auto;
      }
    }
    .page-three {
      .copy-container.flexible {
        height: calc(100vh - 138px - 84px);
        overflow: auto;
      }
      .no-zoom input {
        width: 90vw;
      }
    }
    .input-form {
      padding: 0 16px;
    }
  }
}
/* landscape */
@media screen and (min-aspect-ratio: 13/9) {
  /* landscape-specific styles */
  .splash-screen.tablet {
    .illustration-container {
      height: 75vh;
      width: 75vh;
      margin-top: 12.5vh;
      float: left;
      text-align: center;
      .term-content iframe {
        // width, minus margins and borders and padding
        width: calc(75vh - 32px - 2px - 24px);
        height: calc(calc(75vh - 32px - 2px - 24px) / 16 * 9);
      }
      .term-content-inner {
        // 100 - nav - logo - parent margin/padding
        height: calc(75vh - 40px - 68px - 32px - 24px);
        overflow: auto;
        padding: 0 12px;
      }
    }
    .copy-container {
      margin-top: 12.5vh;
      height: 75vh;
      width: calc(100vw - 75vh);
      float: left;
      text-align: left;
      padding: 0 18px;
      overflow: auto;
      &.flexible {
        position: relative;
        .input-form {
          padding: 16px;
          bottom: 50%;
          margin-bottom: -161px;
        }
      }
      > button {
        position: absolute;
        margin: 0;
        bottom: 50%;
        margin-bottom: -19px;
      }
    }
  }
  .splash-screen.mobile {
    .illustration-container {
      height: calc(100vh - 40px);
      width: calc(100vh - 40px);
      float: left;
      .term-content iframe {
        // width, minus margins and borders and padding
        width: calc(100vh - 40px - 32px - 2px - 24px);
        height: calc(calc(100vh - 40px - 32px - 2px - 24px) / 16 * 9);
      }
      .term-content-inner {
        // 100 - nav - logo - parent margin/padding
        height: calc(100vh - 40px - 68px - 32px - 24px);
        overflow: auto;
        padding: 0 12px;
      }
    }
    .copy-container {
      height: calc(100vh - 44px);
      width: calc(100vw - (100vh - 40px));
      float: left;
      text-align: left;
      padding: 0 18px;
      overflow: auto;
    }
    .page-three {
      .md-floating-label {
        font-size: 12px;
        top: -5px;
      }
      .md-text-field {
        font-size: 12px;
      }
      .md-text-field--floating-margin {
        margin-top: 30px;
      }
      .no-zoom input {
        font-size: 16px;
        transform-origin: top left;
        transform: scale(0.75);
      }
      .splash-button {
        margin: 0px;
      }
      .copy-container.flexible {
        .input-form {
          margin-bottom: 40px;
        }
      }
    }
  }
}
