.hf-marketplace-dashboard {
  padding: 24px;

  &-step {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: $md-theme-blue;
    font-size: 16px;
    line-height: 22px;
    margin: 3px 0;

    &.current {
      font-weight: 700;
    }

    &.completed {
      color: #31b48d;
      font-weight: 700;
    }

    .md-icon.material-icons {
      color: #31b48d;
      font-size: 20px;
    }
  }

  &-card {
    background-color: rgba($md-secondary-color, 10%);
  }

  &-add-card {
    background-color: $md-primary-color;
    display: flex;
    align-items: center;
    column-gap: 16px;
    cursor: pointer;
    transition: background-color ease-in-out 0.25s;
    padding: 16px;
    margin: 16px 16px 0;

    &:hover {
      background-color: rgba($md-primary-color, 0.85);
    }

    &:active {
      background-color: color.scale($md-primary-color, $lightness: -25%);
    }

    .md-icon.material-icons {
      font-size: 48px;
      color: $md-white-base;
    }

    .md-title {
      color: $md-white-base;
      font-weight: 900;
      margin: 0;
      text-wrap: wrap;
    }
  }
}

.hf-marketplace-list {
  margin: 16px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  height: 100%;
}

.hf-marketplace-list-item {
  border-radius: 12px;
  overflow: hidden;
  height: 256px;
  width: 448px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0;

  .md-card-title.md-card-title--primary {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    .md-icon.material-icons {
      color: $md-red-500;
    }
  }

  .md-card-text {
    padding: 0px 16px;
    margin-bottom: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    z-index: 4;
  }

  img {
    width: 100%;
    max-height: 112px;
    object-fit: cover;
    object-position: center 30%;

    &.hf-marketplace {
      &-service {
        object-position: center bottom;
      }
      &-provider {
        object-position: center 65%;
      }
    }
  }
}

.hf-marketplace-delete-dialog {
  width: 320px;
  border-radius: 12px;

  &-actions {
    padding: 24px;
  }
}
