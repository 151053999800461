.hf-marketplace-form {
  &-header {
    display: flex;
    column-gap: 8px;
    align-items: baseline;
    padding: 16px 16px 0;
  }

  &-paper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: rgba(#8a5bda, 10%);
    border-radius: 12px;
    box-shadow: none;

    @media only screen and (max-width: 568px) {
      flex-direction: column;
    }
  }

  &-image {
    border-radius: 12px;
    height: 256px;

    @media only screen and (max-width: 568px) {
      height: 192px;
    }
  }

  &-actions {
    display: flex;
    justify-content: right;
    padding: 24px 64px 24px;
  }

  .hf-multilang-tabs {
    background-color: $md-white-base;
    overflow: hidden;
    border-radius: 12px;
    border: 2px solid transparent;
    transition:
      border-color ease-in-out 0.25s,
      height ease-in-out 0.25s;
    margin: 0px;

    &:hover {
      border-color: $md-grey-600;
    }

    &:focus-within {
      border-color: $md-primary-color;
    }

    &:has(.md-text--error) {
      border-color: $md-red-500;
    }
  }

  .ql-container.ql-snow {
    border: none;
  }
}
