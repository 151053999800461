.hf-public-view {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media #{$md-desktop-media} {
    height: calc(100% - $md-toolbar-tablet-height);
    flex-direction: row;
    justify-content: flex-end;
  }

  &-background-1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('/images/bg.jpg') lightgray 50% / cover no-repeat;
    filter: blur(125px);
    z-index: 0;
  }

  &-background-2 {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-woman {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: contain;
    z-index: 2;

    @media #{$md-mobile-media} {
      display: none;
    }

    @media #{$md-tablet-media} {
      display: none;
    }

    @media #{$md-desktop-media} {
      display: block;
    }
  }

  &-welcome {
    position: relative;
    max-width: 416px;
    margin: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    text-wrap: balance;
    z-index: 3;

    @media #{$md-desktop-media} {
      max-width: 320px;
      top: calc(-18vh + $md-toolbar-tablet-height);
      text-align: left;
      align-items: flex-start;
    }

    @media only screen and (min-width: 1280px) {
      max-width: 380px;
      top: calc(-28vh + $md-toolbar-tablet-height);
    }

    @media only screen and (min-width: 1600px) {
      max-width: 25%;
      top: calc(-32vh + $md-toolbar-tablet-height);
    }
  }

  &-card {
    position: relative;
    min-width: 300px;
    max-height: calc(100% - 48px);
    width: 416px;
    padding: 24px 48px;
    border-radius: 12px;
    background-color: #f9fafc;
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    row-gap: 18px;
    overflow: auto;
    z-index: 4;

    @media only screen and (max-width: 500px) {
      width: calc(100% - 48px);
    }

    @media #{$md-desktop-media} {
      margin-right: 6vw;
      margin-bottom: 0;
    }

    @media only screen and (min-width: 1600px) {
      margin-right: 10vw;
    }
  }

  &-title {
    color: #2d2aa5;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
  }

  &-subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    margin: 0;

    &--large {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &-message {
    color: #2d2aa5;
    font-size: 50px;
    font-weight: 400;
    line-height: 55px;
    letter-spacing: -1px;
  }

  &-form {
    display: flex;
    flex-direction: column;
    row-gap: 18px;

    &-container {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }
  }

  &-input {
    padding: 1px 15px;
    border: 2px solid #d8e6f7;
    border-radius: 8px;
    background-color: #fff;
    height: 46px;
    transition: border-color ease-in-out 0.25s;
    z-index: 4;

    &:hover {
      border-color: $md-grey-700;
    }

    &:focus-within {
      border-color: $md-primary-color;
    }

    input {
      color: $md-grey-900;
      font-size: 16px;
    }

    button {
      right: 7px;
      top: 9px;
    }

    hr {
      display: none;
    }

    &-group {
      display: flex;
      column-gap: 16px;

      .hf-public-view-input-container {
        flex: 1;
      }
    }

    &-container {
      height: 64px;
      transition: height ease-in-out 0.25s;

      &:has(.hf-public-view-input-error) {
        height: 82px;

        .hf-public-view-input {
          border-color: $md-red-500;
        }
      }
    }

    &-error {
      font-size: 12px;
      color: $md-red-500;
      padding: 2px 4px 0px;
      animation: slide-down 0.25s forwards;
      transform: translateY(-100%);
      z-index: 2;

      @keyframes slide-down {
        100% {
          transform: translateY(0);
        }
      }
    }
  }

  &-button {
    border-radius: 28px;
    height: 44px;
    font-size: 24px;
    letter-spacing: 0.0625rem;
  }

  &-sup-link {
    font-size: 14px;
  }

  &-link {
    color: $md-primary-color;
    text-decoration: none;
    background-image: linear-gradient($md-primary-color, $md-primary-color);
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;
    font-weight: 700;
    font-size: 18px;

    &:hover,
    &:focus,
    &:active {
      background-size: 100% 0.1em;
    }

    &.hf-public-view-link--small {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
    }
  }

  &-error {
    font-size: 14px;
    color: $md-red-500;
    font-weight: 500;
  }

  &-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
