@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;800&display=swap');
.safely-splash {
  .locale-margin {
    margin-top: 48px;
    // border: 1px solid black;
    min-height: 300px;
  }
  h1 {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    text-decoration: none;
    color: #19172a;
    font-size: 48px;
    line-height: 1;
    letter-spacing: 0px;
    text-transform: none;
  }
  p {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    line-height: 1.5;
    letter-spacing: 0px;
    text-transform: none;
    color: #000000;
  }
  .provided-by {
    font-family: 'Nunito Sans', sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0px;
    text-transform: none;
    color: #000000;
  }
  .safely-content {
    padding: 0 3%;
  }
  li {
    list-style: disc;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: normal;
    font-size: 20px;
    text-align: left;
    line-height: 1.5;
    letter-spacing: 0px;
    text-transform: none;
    color: #000000;
  }
  .safely-cta {
    font-family: 'Nunito Sans', sans-serif;
    font-style: normal;
    font-weight: 800;
    text-decoration: none;
    background-color: rgba(64, 202, 161, 1);
    border: 1px solid #40caa1;
    border-radius: 50px;
    color: #ffffff;
    fill: #ffffff;
    font-size: 16px;
    padding: 12px 44px;
    text-transform: none;
    height: auto;
    &.md-btn--hover {
      background-color: rgba(0, 158, 131, 1);
    }
  }
}

/* desktop */
.safely-splash.desktop {
  margin-top: 100px;
  max-width: 1300px;
  margin: 100px auto 0;
}

@media screen and (max-width: 1024px) {
  .safely-splash {
    .locale-margin {
      margin-top: 64px;
    }
    .provided-by-wrapper {
      padding: 0 10%;
    }
  }
}
