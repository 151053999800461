#gbWizard {
  border-radius: 8px;
}

.wizard-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  column-gap: 12px;
}
