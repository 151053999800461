.hf-list-item-modal {
  position: relative;
  .md-list-tile {
    padding-right: 18px;
  }

  .hf-list-item-more {
    position: absolute;
    bottom: calc(50% - 14px);
    right: 5px;
    font-size: 20px;
    line-height: 28px;
    color: $md-primary-color;
    cursor: pointer;
    .hf-more-span {
      opacity: 0;
      display: none;
    }
    &:hover,
    &:active,
    &:focus {
      // height: 100%;
      // width: 100%;
      background: rgba(244, 244, 244, 0.85);
      .hf-more-span {
        opacity: 1;
        display: inline-block;
      }
    }
  }
}

.list-item-modal-content {
  padding: 16px;
}
.hf-guest-modal {
  .md-paper {
    background-color: rgba(224, 224, 224, 0.95);
  }
  header.md-paper.md-paper--2 {
    background-color: transparent;
    box-shadow: none;
    .md-toolbar--action-left,
    .md-title--toolbar {
      display: none;
    }
  }
  .md-card {
    margin-left: 20%;
    margin-right: 20%;
    background-color: white;
    min-height: 67vh;
    max-height: calc(
      100vh - 64px
    ); // 16px margin for self and for container on both top and bottom
    overflow-x: auto;
  }
}
.list-item-modal-content {
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    white-space: normal;
  }
}
.list-item-modal-close {
  z-index: 100;
  position: fixed;
  right: calc(20% + 16px);
  top: 42px; // 32 px (16px margin for two elements plus 10px padding)
}
//Reduce margin on phone.
@media screen and (max-width: $md-tablet-min-width) {
  .hf-guest-modal {
    header.md-paper.md-paper--2 {
      display: none;
    }
    .md-card {
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  .list-item-modal-close {
    position: fixed;
    right: 26px;
    top: 42px; // 32 px (16px margin for two elements plus 10px padding)
  }
}
@media screen and (max-width: $md-desktop-min-width) {
  .hf-guest-modal {
    /*control size of any images or videos inserted into iframes*/
    iframe {
      max-width: 100%;
      min-height: 200px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
