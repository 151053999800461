@use 'sass:color';

@import '~normalize.css';
@import '~react-md/src/scss/_react-md.scss';
@import '~react-quill/dist/quill.core.css';
@import '~react-quill/dist/quill.snow.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~rc-time-picker/assets/index.css';

/*
==============================================================================================================
  setup style overrides for our site
==============================================================================================================
 */
$md-mobile-min-width: 320px;
$md-grid-tablet-breakpoint: 768px;
$md-tablet-min-width: 768px;
$md-grid-desktop-breakpoint: 1024px;
$md-desktop-min-width: 1024px;

// Hostfully Theme colors
$md-primary-color: #40caa1;
$md-secondary-color: #8a5bda;
$md-tertiary-color: #546e7a;
$md-theme-yellow: #ffc454;
$md-theme-orange: #ff7254;
$md-theme-blue: #2d2aa5;
$md-grey-1: #19172a;
$md-grey-2: #3c3a46;
$md-grey-3: #f3f3f7;

$md-data-table-contextual-fallback-color: rgba($md-grey-2, 0.32);
$md-linear-progress-fallback-color: rgba($md-grey-2, 0.54);
$md-switch-fallback-color: rgba($md-grey-2, 0.87);

$md-drawer-desktop-width: 300px;
$md-drawer-tablet-width: 300px;

$md-font-icon-include-dense: false;

$md-avatar-colors: (
  'primary': $md-primary-color $md-white-base,
  'secondary': $md-secondary-color $md-white-base,
  'tertiary': $md-grey-400 $md-white-base,
  'hfprimaryavatar': $md-light-blue-500 $md-white-base,
  'hfsecondaryavatar': $md-deep-orange-a-700 $md-white-base,
  'hfred': $md-red-500 $md-white-base,
  'hforange': $md-deep-orange-500 $md-white-base,
  'hfamber': $md-amber-500 $md-white-base,
  'hfgreen': $md-green-600 $md-white-base,
  'hfteal': $md-teal-600 $md-white-base,
  'hfdarkblue': $md-blue-500 $md-white-base,
  'hfpurple': $md-purple-500 $md-white-base,
  'hfminimalgrey': $md-grey-200 $md-grey-900,
  'hflightblue': $md-light-blue-300 $md-white-base,
  'hfbluegrey': $md-blue-grey-800 $md-white-base,
  'hfbrown': $md-brown-500 $md-white-base
);

:root {
  scroll-behavior: smooth;
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;

  transition: scrollbar-color 300ms ease-in-out;

  &:hover {
    scrollbar-color: rgb(0 0 0 / 40%) transparent;
  }
}

.gbHostOpenBetaBanner {
  background-color: #8a5bda;
  position: sticky;
  top: 0px;
  z-index: 100;
  width: 100%;
  transition: transform 500ms ease-in-out;
  display: flex;
  column-gap: 16px;
  align-items: center;
  padding: 8px 16px;

  &.close {
    transform: translateY(-200%);
    display: none;
  }

  button {
    border-radius: 20px;
    background-color: #fff;
    color: $md-primary-color;
    &.outlined {
      background-color: transparent;
      color: #fff;
      border: 1px #fff solid;
    }
  }

  label {
    color: #fff;
    .md-text--theme-secondary {
      color: #fff;
    }
    i {
      color: $md-primary-color;
    }
  }

  .gbHost-title {
    font-size: 20px;
    font-weight: 700;
    color: #fff;
    margin: 0;
  }

  .gbHost-content {
    flex: 1;
    color: #fff;
    font-size: 16px;
  }
}

.hf-banner > div:not(.gbHostOpenBetaBanner) {
  position: relative;
  > header,
  > aside,
  > nav {
    top: 48px;
  }
}

/* Avoid dislocated LinearProgress from top bar*/
.md-progress {
  margin: 0 !important;
}

/*make icons white inside card titles with dark colors*/
.md-avatar--hforange,
.md-avatar--hfred,
.md-avatar--hfgreen,
.md-avatar--hfdarkblue,
.md-avatar--hfpurple {
  svg.hf-icon {
    fill: $md-white-base;
  }
}

.md-text--theme-tertiary {
  color: $md-tertiary-color;
  .md-icon,
  .md-icon.md-text--inherit {
    color: $md-tertiary-color;
  }
  .md-icon-text {
    color: $md-tertiary-color;
  }
}

$md-media-aspect-ratios: (
  '21:15',
  '20:13',
  '16:9',
  '7:4',
  '6:8',
  '4:3',
  '4:1',
  '1:1'
);

@include react-md-everything;

.hf-center-loading {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#main-desktop {
  background-color: $md-grey-3;
}

.hf-toolbar {
  background-color: $md-theme-blue;

  > button.md-btn--toolbar > i {
    color: $md-white-base;
  }
}

.hf-toolbar-guest {
  background-color: #fff;
  .md-cell--right.md-toolbar--action-right {
    display: flex;
    align-items: center;
  }
}

.hf-title {
  color: #2d2aa5;
  font-weight: 700;

  @media only screen and (min-width: 1024px) {
    font-size: 44px;
    line-height: 50px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 40px;
    line-height: 46px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 28px;
    line-height: 34px;
  }
}

.hf-subtitle {
  color: #212529;
  font-weight: 500;

  @media only screen and (min-width: 1024px) {
    font-size: 24px;
    line-height: 30px;
  }

  @media only screen and (max-width: 1023px) {
    font-size: 20px;
    line-height: 26px;
  }

  @media only screen and (max-width: 700px) {
    font-size: 16px;
    line-height: 22px;
  }
}

.hf-sticky {
  position: sticky;
  bottom: 0px;
  background-color: rgba(#fff, 0.92);
  display: flex;
  justify-content: flex-end;
  padding: 16px 8px;
  column-gap: 16px;
  z-index: 2;

  @media only screen and (max-width: 700px) {
    padding-right: 56px;
    margin: 0;
  }
}

.hf-mini-btn {
  padding: 2px;
  height: 24px;
  width: 24px;

  .md-icon.material-icons {
    font-size: 20px;
  }
}

.hf-new-item {
  bottom: 64px;
  right: 8px;
}

.hf-main-title {
  color: $md-theme-blue;
  font-size: 36px;
  font-weight: 700;
  line-height: 41px;
  margin-bottom: 16px;
}

.hf-secondary-title {
  color: $md-secondary-color;
  font-size: 30px;
  font-weight: 500;
}

.hf-card {
  border-radius: 12px;
  padding: 24px;
}

.hf-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
  padding: 16px 24px;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    display: grid;
  }

  .hf-form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 16px;
    width: 100%;

    > * {
      flex: calc(50% - 32px);
    }

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      > * {
        flex: unset !important;
      }
    }
  }
}

.hf-input {
  padding: 1px 15px;
  border: 2px solid transparent;
  border-radius: 8px;
  background-color: #fff;
  cursor: text;
  height: 46px;
  transition:
    border-color ease-in-out 0.25s,
    height ease-in-out 0.25s;

  &:has(.md-select-field.md-text--disabled) {
    cursor: default;
    pointer-events: none;
  }

  &:hover {
    border-color: $md-grey-600;
  }

  &:focus-within {
    border-color: $md-primary-color;
  }

  &:has(.md-text--error) {
    border-color: $md-red-500;
  }

  &.hf-text-area {
    height: auto;
    .md-text-field-message-container {
      position: absolute;
      bottom: -20px;
    }
  }

  &-container {
    height: 64px;

    &:has(.md-text--error) {
      height: 82px;

      .hf-input {
        border-color: $md-red-500;
      }
    }
  }

  &-error {
    font-size: 12px;
    color: $md-red-500;
    padding: 2px 4px 0px;
    z-index: 2;
  }

  input,
  textarea {
    color: $md-grey-900;
  }

  button {
    right: 7px;
    top: 9px;
  }

  hr {
    display: none;
  }

  .md-text-field-message-container {
    margin-left: -16px;
    margin-top: 16px;

    .md-text--error {
      animation: slide-down 0.25s forwards;
    }

    .md-text--disabled {
      transform: translateY(-100%);
    }

    @keyframes slide-down {
      100% {
        transform: translateY(0);
      }
    }
  }
}

$hf-title-padding: 8px;
$hf-sponsored-color: #4caf50;
.hf-orange {
  background-color: $md-red-500;
}
.hf-orange-button {
  background-color: $md-orange-500;
}
.hf-purple-flat-button {
  color: rgb(156, 39, 176);
  &.md-btn--hover {
    background-color: rgba(156, 39, 176, 0.12);
  }
}
.hf-relative {
  position: relative;
}

.hf-logo-container {
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 100%;
  max-width: 312px;
  margin-left: 16px;

  &.hf-host {
    margin-left: 16px !important;
  }

  a {
    display: flex;
    height: 100%;
    width: 100%;
    padding: 8px 0px;
  }

  .hf-logo {
    max-height: 100%;
    max-width: 100%;
    margin: auto 0px;
  }

  .hf-logo-subtitle {
    color: #fff;
    font-size: 20px;
  }
}

.hf-icon {
  width: 30px;
  height: 30px;
  fill: $md-grey-2;
  color: $md-grey-2;
}
.hf-notice {
  background-color: $md-grey-100;
  padding: 12px;
}
/*remove react-md dark border around avatars*/
.md-avatar.md-avatar--default,
.md-avatar.md-avatar--card {
  border: 1px solid white;
}
.test-import-hf {
  color: green;
}
/*add text protection scrim consistent with MD spec*/
.md-media-overlay {
  top: 0px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 30%,
    rgba(0, 0, 0, 0.3)
  );

  > .splash-logo {
    position: absolute;
    bottom: 0px;
  }
  /*position text inside overlay at the bottom*/
  > .md-card-title.md-card-title--primary {
    position: absolute;
    bottom: 0px;

    /*add text shadow to enhance readability of text over scrim*/
    > h2.md-card-title--title.md-card-title--large {
      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
ul.md-list.md-list--drawer {
  position: relative;
  min-height: calc(100vh - #{$md-toolbar-tablet-height});
  padding-bottom: 70px;
  overflow-y: auto;
}
ul.md-list--nested-1.not-nested {
  .md-list-tile {
    padding-left: 24px;
  }
}
nav > footer {
  position: absolute;
  bottom: 0px;
  background: $md-white-base;
  padding: 12px 0px;
  border-top: $md-grey-100 1px solid;
  width: 100%;
}
/*treat overflow on host quote*/
.host-quote h3.md-text--secondary {
  white-space: pre;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

/*sponsored recs avatar*/
img.sponsored-avatar {
  width: 40px;
  height: 40px;
  padding: 4px;
  left: initial;
}

.hf-link {
  color: $md-primary-color;
  cursor: pointer;
  text-decoration: none;
  background-image: linear-gradient($md-primary-color, $md-primary-color);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
  border: none;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    background-size: 100% 0.1em;
  }
}
span.link-span.hf-grey-link,
.hf-grey-link {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
  background-image: linear-gradient($md-primary-color, $md-primary-color);
  background-size: 0% 0.1em;
  background-position-y: 100%;
  background-position-x: 0%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;

  &:hover,
  &:focus,
  &:active {
    background-size: 100% 0.1em;
  }
}

.hf-external-link {
  color: $md-theme-blue;
  background-image: linear-gradient($md-theme-blue, $md-theme-blue);
}

.hf-about {
  display: inline-flex;
  margin: 48px 16px 16px;
}

.hf-copy-link:hover {
  text-decoration: underline;
}

.csv-link {
  color: $md-primary-color;
  cursor: pointer;
}
.csv-link:hover,
.csv-link:focus {
  text-decoration: underline;
}

/*Validation errors in forms */
.error {
  color: $md-secondary-color;
}

.no-click {
  .md-pointer--hover {
    cursor: default;
  }
  .md-ink-container {
    opacity: 0;
  }
  .md-list-tile--active {
    background: transparent;
  }
}

.show-pointer {
  cursor: pointer;
}
.hf-relatively {
  position: relative;
}
.hf-absolutely {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.hf-rec-button {
  height: 64px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  .md-icon-separator {
    display: block;
    position: relative;
    text-align: center;
    .md-icon {
      display: block;
      width: 24px;
      margin: auto;
    }
    .md-icon,
    .md-icon-text {
      display: block;
      padding-left: 0px;
    }
  }
}
.hf-rec-buttons > .md-toolbar--action-right {
  > a,
  button {
    height: 64px;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 8px 8px;
    .md-icon-separator {
      display: block;
      position: relative;
      text-align: center;
      .md-icon {
        display: block;
        width: 24px;
        margin: auto;
        padding-top: 0px;
      }
      .md-icon,
      .md-icon-text {
        display: block;
        padding-left: 0px;
      }
    }
  }
}
@media only screen and (max-width: 550px) {
  .hf-rec-buttons > .md-toolbar--action-right {
    > a,
    button {
      .md-icon-separator {
        .md-icon-text {
          font-size: 12px;
        }
      }
    }
  }
}

.hf-md-padding-48 {
  padding-top: 48px;
}
.hf-display-4-margin {
  margin-bottom: 48px;
}
.hf-display-3-margin {
  margin-bottom: 44px;
}
.hf-display-2-margin {
  margin-bottom: 40px;
}
.hf-display-1-margin {
  margin-bottom: 36px;
}
.hf-headline-margin {
  margin-bottom: 32px;
}
.hf-title-margin {
  margin-top: 32px;
}
.hf-title-margin {
  margin-bottom: 28px;
}
.hf-subheading-margin {
  margin-bottom: 24px;
}
.hf-body-2-margin {
  margin-bottom: 20px;
}
.hf-body-1-margin {
  margin-bottom: 16px;
}
/*Host UI */
.hf-form-wrapper {
  padding: 16px 36px;
  background-color: $md-white-base;
  margin: 24px;
}
.hf-button-margin {
  margin-top: 2em;
  margin-bottom: 1em;
}
.hf-add-another {
  margin-left: 8px;
}
.hf-button-caption-margin {
  margin-top: 3em;
  margin-bottom: 1em;
}
h2.hf-error {
  color: red;
}
h4.hf-error {
  color: red;
}
div.hf-error {
  border-top: red 1px solid;
  margin-top: -2px;
  margin-left: -1px;
  margin-right: -1px;
  font-size: 12px;
  color: red;
  > span {
    margin: 4px 8px;
    display: block;
  }
}
.hf-form-errors div.hf-error {
  border-top: none;
}
.hf-color-picker-wrapper {
  height: 140px;
  position: relative;
}
.hf-color-picker {
  height: 128px;
  position: absolute;
}
.ql-snow .ql-tooltip {
  transform: translateX(120px);
}
span.link-span {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}
.hf-multilang-editor {
  .ql-toolbar.ql-snow {
    border: none;
    font-family: Roboto;
    border-bottom: $md-grey-100 1px solid;

    .ql-picker.ql-font {
      .ql-picker-item[data-value='Arial']::before,
      .ql-picker-label[data-value='Arial']::before {
        content: 'Arial';
        font-family: Arial, sans-serif;
      }
      .ql-picker-item[data-value='Verdana']::before,
      .ql-picker-label[data-value='Verdana']::before {
        content: 'Verdana';
        font-family: Verdana, sans-serif;
      }
      .ql-picker-item[data-value='Tahoma']::before,
      .ql-picker-label[data-value='Tahoma']::before {
        content: 'Tahoma';
        font-family: Tahoma, sans-serif;
      }
      .ql-picker-item[data-value='Trebuchet']::before,
      .ql-picker-label[data-value='Trebuchet']::before {
        content: 'Trebuchet MS';
        font-family:
          Trebuchet MS,
          sans-serif;
      }
      .ql-picker-item[data-value='Times']::before,
      .ql-picker-label[data-value='Times']::before {
        content: 'Times New Roman';
        font-family:
          Times New Roman,
          serif;
      }
      .ql-picker-item[data-value='Georgia']::before,
      .ql-picker-label[data-value='Georgia']::before {
        content: 'Georgia';
        font-family: Georgia, serif;
      }
      .ql-picker-item[data-value='Garamond']::before,
      .ql-picker-label[data-value='Garamond']::before {
        content: 'Garamond';
        font-family: Garamond, serif;
      }
      .ql-picker-item[data-value='Courier']::before,
      .ql-picker-label[data-value='Courier']::before {
        content: 'Courier New';
        font-family:
          Courier New,
          monospace;
      }
      .ql-picker-item[data-value='Brush']::before,
      .ql-picker-label[data-value='Brush']::before {
        content: 'Brush Script MT';
        font-family:
          Brush Script MT,
          cursive;
      }
    }
    .ql-formats > button,
    .ql-formats > span.ql-picker {
      position: relative;
      &:hover::after,
      &:focus::after,
      & > .ql-picker-label:focus::after {
        content: '';
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        color: #ffffff;
        background: #03a9f4;
        height: 24px;
        width: auto;
        border-radius: 4px;
        padding: 4px 8px;
        position: absolute;
        top: 50%;
        margin-top: -12px;
        left: 100%;
        margin-left: 4px;
        z-index: 100;
      }
      &.tooltip-left:hover::after,
      &.tooltip-left:focus::after,
      &.tooltip-left > .ql-picker-label:focus::after {
        margin-right: 4px;
        margin-left: 0px;
        right: 100%;
        left: auto;
      }
      &:hover::before,
      &:focus::before,
      & > .ql-picker-label:focus::before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 100%; /* To the left of the tooltip */
        margin-left: -6px;
        margin-top: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent #03a9f4 transparent transparent;
      }
      &.tooltip-left:hover::before,
      &.tooltip-left:focus::before,
      &.tooltip-left > .ql-picker-label:focus::before {
        border-color: transparent transparent transparent #03a9f4;
        margin-right: -6px;
        margin-left: 0px;
        right: 100%;
        left: auto;
      }
      &.ql-bold:hover::after,
      &.ql-bold:focus::after {
        content: 'Bold';
      }
      &.ql-italic:hover::after,
      &.ql-italic:focus::after {
        content: 'Italic';
      }
      &.ql-underline:hover::after,
      &.ql-underline:focus::after {
        content: 'Underline';
      }
      &.ql-link:hover::after,
      &.ql-link:focus::after {
        content: 'Link/Unlink' '\00a0' 'Text';
      }
      &.ql-image:hover::after,
      &.ql-image:focus::after {
        content: 'Insert' '\00a0' 'Image';
      }
      &.ql-blockquote:hover::after,
      &.ql-blockquote:focus::after {
        content: 'Quote' '\00a0' 'Text';
      }
      &.ql-clean:hover::after,
      &.ql-clean:focus::after {
        content: 'Remove' '\00a0' 'Formatting';
      }
      &.ql-list[value='ordered']:hover::after,
      &.ql-list[value='ordered']:focus::after {
        content: 'Ordered' '\00a0' 'List';
      }
      &.ql-list[value='bullet']:hover::after,
      &.ql-list[value='bullet']:focus::after {
        content: 'Bullet' '\00a0' 'List';
      }
      &.ql-indent[value='-1']:hover::after,
      &.ql-indent[value='-1']:focus::after {
        content: 'Decrease' '\00a0' 'Indent';
      }
      &.ql-indent[value='+1']:hover::after,
      &.ql-indent[value='+1']:focus::after {
        content: 'Increase' '\00a0' 'Indent';
      }
      &.ql-color:hover::after,
      &.ql-color > .ql-picker-label:focus::after {
        content: 'Text' '\00a0' 'Color';
      }
      &.ql-background:hover::after,
      &.ql-background > .ql-picker-label:focus::after {
        content: 'Background' '\00a0' 'Color';
      }
      &.ql-card:hover::after,
      &.ql-card:focus::after {
        content: 'Dynamic' '\00a0' 'Guidebook' '\00a0' 'Link';
      }
      &.ql-font:hover::after,
      &.ql-font:focus::after {
        content: 'Font';
      }
      &.ql-pdf:hover::after,
      &.ql-pdf:focus::after {
        content: 'Insert' '\00a0' 'PDF';
      }
      &.ql-var:hover::after,
      &.ql-var:focus::after {
        content: 'Insert' '\00a0' 'Variable';
      }
    }
  }
  .ql-container.ql-snow {
    min-height: 160px;
    border-left: none;
    border-right: none;
  }
  .ql-toolbar.ql-snow + .ql-container.ql.snow {
    border-top: 1px solid rgba(0, 0, 0, 0.117647);
    border-color: rgba(0, 0, 0, 0.117647);
    border-radius: 2px;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
  }
}

/* custom font overrides */
.ql-font-Arial {
  content: 'Arial';
  font-family: Arial, sans-serif !important;
}
.ql-font-Verdana {
  content: 'Verdana';
  font-family: Verdana, sans-serif !important;
}
.ql-font-Tahoma {
  content: 'Tahoma';
  font-family: Tahoma, sans-serif !important;
}
.ql-font-Trebuchet {
  content: 'Trebuchet MS';
  font-family: 'Trebuchet MS', sans-serif !important;
}
.ql-font-Times {
  content: 'Times New Roman';
  font-family: 'Times New Roman', serif !important;
}
.ql-font-Georgia {
  content: 'Georgia';
  font-family: Georgia, serif !important;
}
.ql-font-Garamond {
  content: 'Garamond';
  font-family: Garamond, serif !important;
}
.ql-font-Courier {
  content: 'Courier New';
  font-family: 'Courier New', monospace !important;
}
.ql-font-Brush {
  content: 'Brush Script MT';
  font-family: 'Brush Script MT', cursive !important;
}

.hf-multilang-editor.hf-textarea {
  min-height: 200px;
  .ql-container.ql-snow {
    min-height: 160px;
  }
}
.hf-autoalign {
  text-align: auto !important;
  text-align: -webkit-auto;
}
.hf-fullwidth {
  width: 100%;
}
.hf-editable-card-tabs,
.hf-editable-grid-lists {
  .md-grid {
    background-color: $md-grey-100;
  }
  .hf-guidebookslist-item,
  .hf-recommendationslist-item,
  .hf-listingslist-item,
  .hf-informationslist-item,
  .hf-marketplacelist-item {
    background-color: $md-white-base;
  }
}
.hf-editable-grid-lists .hf-informationslist-item {
  height: 78px;
  .md-list-tile {
    padding-left: 0px;
    padding-right: 0px;
  }
  .md-ink-container {
    height: 78px;
  }
  .md-avatar {
    height: 48px;
  }
  .md-tile-content.md-tile-content--left-avatar {
    display: inline-block;
    position: absolute;
    top: 48px;
    left: 0px;
    padding-left: 0px;
    padding-right: 0px;
    width: 100%;
    .md-text {
      margin-top: 9px;
      text-align: center;
    }
  }
}

.md-tabs {
  .md-tab-indicator {
    background: $md-primary-color;
  }
  .md-tab {
    background-color: $md-white-base;
    justify-content: center;
    transition: all 0.25s ease-in-out;

    &:hover {
      background-color: $md-grey-100;
    }

    &.md-tab--active {
      background-color: $md-grey-100;
      color: $md-primary-color;
    }

    &.md-tab--inactive {
      color: $md-grey-2;
    }
  }

  &:not(.hfTabs) .md-tab {
    border-radius: 12px 12px 0 0;
    margin: 2px 2px 0px 2px;
    padding: 16px 24px;
  }
}

.hf-editable-card-tabs,
.hf-multilang-tabs {
  width: 100%;
  min-width: 300px;
  border-bottom: 1px solid $md-grey-100;

  .md-tabs {
    border-bottom: 1px solid $md-grey-100;
  }

  .md-paper {
    border-radius: 12px;
    overflow: hidden;
  }
}
.hf-loading {
  height: 100%;
}
.hf-loading-paper {
  min-height: 500px;
}
div.hf-images-grid {
  padding: 1px;
}
div.hf-images-grid.selected {
  :after {
    content: '';
    border-radius: 50%;
    background-color: $md-white-base;
    background-image: url('https://storage.googleapis.com/hostfully-wp-1/hf-check-circle-blue.svg');
    background-size: contain;
    background-position: top right;
    position: absolute;
    right: 0px;
    height: 44px;
    width: 44px;
  }
}
.icon-chooser {
  text-align: center;
  &.selected {
    .md-avatar {
      background-color: #9c27b0;
    }
    .md-avatar:before {
      content: '';
      background-image: url('https://storage.googleapis.com/hostfully-wp-1/hf-check-white.png');
      background-size: cover;
      background-position: center center;
      position: absolute;
      height: 44px;
      width: 44px;
      margin-left: -24px;
    }
  }
  .md-tile-addon.md-tile-addon--avatar {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }
  .md-avatar {
    height: 48px;
    width: 48px;
    border: 2px solid #9c27b0;
    background-color: white;
  }
  .md-tile-text--primary.md-text {
    font-size: 12px;
  }
}

.hf-filestack.md-btn--hover {
  background-color: transparent;
  &:hover,
  &:active,
  &:focus {
    background: rgba(153, 153, 153, 0.12);
  }
}

.hf-float-right {
  float: right;
}
form .map-container.md-toolbar-relative {
  margin-top: 0px;
}
//change disabled style to be consistent with MD spec.
.md-text-field-container--disabled {
  border-bottom: 1px $md-grey-300 dashed;
  > hr {
    display: none;
  }
}
.hf-fake-link {
  text-decoration: none;
  &active {
    color: rgba(0, 0, 0, 0.87);
  }
  &visited {
    color: rgba(0, 0, 0, 0.87);
  }
}

p {
  &.ql-indent-1 {
    margin-left: 2.5em;
  }
  &.ql-indent-2 {
    margin-left: 5em;
  }
  &.ql-indent-3 {
    margin-left: 7.5em;
  }
  &.ql-indent-4 {
    margin-left: 10em;
  }
  &.ql-indent-5 {
    margin-left: 12.5em;
  }
  &.ql-indent-6 {
    margin-left: 15em;
  }
  &.ql-indent-7 {
    margin-left: 17.5em;
  }
  &.ql-indent-8 {
    margin-left: 20em;
  }
  &.ql-indent-9 {
    margin-left: 22.5em;
  }
}

/* use quill styles in some places */
.md-card-text,
.term-content,
.hf-host-welcome {
  /* restrict images inside cards to width of container */
  img {
    max-width: 100%;
  }
  /* make sure even empty paragraph tags have height */
  p {
    min-height: 20px;
  }
  blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 80px;
  }
  li:not(.md-list-item) {
    &.ql-indent-1 {
      margin-left: 2.5em;
    }
    &.ql-indent-2 {
      margin-left: 5em;
    }
    &.ql-indent-3 {
      margin-left: 7.5em;
    }
    &.ql-indent-4 {
      margin-left: 10em;
    }
    &.ql-indent-5 {
      margin-left: 12.5em;
    }
    &.ql-indent-6 {
      margin-left: 15em;
    }
    &.ql-indent-7 {
      margin-left: 17.5em;
    }
    &.ql-indent-8 {
      margin-left: 20em;
    }
    &.ql-indent-9 {
      margin-left: 22.5em;
    }
  }
  ul > li:not(.md-list-item):not(.md-tab) {
    line-height: 19px;
    position: relative;
    &:before {
      content: '\2022' ' '; /* The unicode for • character */
      margin-left: -8px;
    }
  }
  ol > li:not(.md-list-item) {
    line-height: 19px;
    position: relative;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8
      list-9;
    counter-increment: list-0;
    &:before {
      content: counter(list-0, decimal) '. ';
      line-height: 19px;
      margin-left: -16px;
    }
    &.ql-indent-1 {
      counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-1;
      &:before {
        content: counter(list-1, lower-alpha) '. ';
      }
    }
    &.ql-indent-2 {
      counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-2;
      &:before {
        content: counter(list-2, lower-roman) '. ';
      }
    }
    &.ql-indent-3 {
      counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-3;
      &:before {
        content: counter(list-3, decimal) '. ';
      }
    }
    &.ql-indent-4 {
      counter-reset: list-5 list-6 list-7 list-8 list-9;
      counter-increment: list-4;
      &:before {
        content: counter(list-4, lower-alpha) '. ';
      }
    }
    &.ql-indent-5 {
      counter-reset: list-6 list-7 list-8 list-9;
      counter-increment: list-5;
      &:before {
        content: counter(list-5, lower-roman) '. ';
      }
    }
    &.ql-indent-6 {
      counter-reset: list-7 list-8 list-9;
      counter-increment: list-6;
      &:before {
        content: counter(list-6, decimal) '. ';
      }
    }
    &.ql-indent-7 {
      counter-reset: list-8 list-9;
      counter-increment: list-7;
      &:before {
        content: counter(list-7, lower-alpha) '. ';
      }
    }
    &.ql-indent-8 {
      counter-reset: list-9;
      counter-increment: list-8;
      &:before {
        content: counter(list-8, lower-roman) '. ';
      }
    }
    &.ql-indent-9 {
      counter-increment: list-9;
      &:before {
        content: counter(list-9, decimal) '. ';
      }
    }
  }
}

/*
==============================================================================================================
  Now add media overrides
==============================================================================================================
 */
@media screen and (min-width: 550px) {
  .hf-multilang-tabs,
  .hf-editable-card-tabs {
    .md-tabs {
      padding-left: 0px !important;
    }
    .md-tab {
      max-width: 100px;
      min-width: 100px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
@media screen and (max-width: $md-grid-desktop-breakpoint) {
  //hide overflowing list titles on smaller screens.
  .hf-editable-card-tabs {
    .md-cell--4 .md-tile-content.md-tile-content--left-avatar {
      max-width: 180px;
      overflow: hidden;
    }
  }
}
@media only screen and (max-width: 700px) {
  //cater for better forms on all phones and small tablets
  .hf-form-wrapper {
    padding: 0;
    margin: 0;

    .md-grid {
      padding: 8px 16px;
    }
  }

  .hf-multilang-editor .ql-container.ql-snow {
    margin-top: 0px;
  }
  //Use up more of the width for selection controls
  .hf-wider-selection-control-container {
    margin-left: -16px;
    margin-right: -16px;
  }
  //Allow for lengthy checkbox labels
  .hf-taller-selection-control-container {
    margin-top: 8px;
    margin-bottom: 24px;
    height: auto;
  }
}
@media only screen and (min-width: 551px) {
  .hf-showOnLessThanPhablet {
    display: none;
  }
  .hf-editable-card-tabs .hf-card-select-list .md-tile-text--primary,
  .hf-editable-card-tabs .hf-card-select-list .md-tile-text--secondary {
    max-width: calc(100% - 48px);
  }
}
@media only screen and (max-width: 550px) {
  .hf-hideOnLessThanPhablet {
    display: none;
  }
  //reduce padding on small devices
  .hf-md-padding-48 {
    padding-top: 8px;
  }
  .hf-display-4-margin {
    margin-bottom: 12px;
  }
  .hf-display-3-margin {
    margin-bottom: 11px;
  }
  .hf-display-2-margin {
    margin-bottom: 10px;
  }
  .hf-display-1-margin {
    margin-bottom: 9px;
  }
  .hf-headline-margin {
    margin-bottom: 8px;
  }
  .hf-title-margin {
    margin-bottom: 7px;
  }
  .hf-subheading-margin {
    margin-bottom: 6px;
  }
  .hf-body-2-margin {
    margin-bottom: 5px;
  }
  .hf-body-1-margin {
    margin-bottom: 4px;
  }
  //modify react-md list control to work better on mobile phones
  .hf-editable-card-tabs .md-tile-text--primary,
  .hf-editable-card-tabs .md-tile-text--secondary {
    max-width: 200px;
  }
  .hf-editable-card-tabs .md-list-tile {
    padding-left: 0px;
  }
  //reduce tile height in icon chooser
  .hf-editable-grid-lists .hf-informationslist-item {
    .md-tile-content.md-tile-content--left-avatar {
      margin-top: 0px;
      .md-text {
        text-align: center;
        margin-top: 9px;
      }
    }
  }
}
@media only screen and (min-width: 1280px) {
  //add classes for xlarge cells to allow space around forms to be reduced on smaller desktops
  .hf-md-cell--2-xlarge {
    width: 16.66667%;
  }
  .hf-md-cell--8-xlarge {
    width: 66.66667%;
  }
}

.intercom-with-namespace-52k34s,
.intercom-lightweight-app-launcher {
  transform: scale(0.8) !important;
  transform-origin: bottom right !important;
  bottom: 8px !important;
  right: 8px !important;

  &:hover {
    transform: scale(1) !important;
  }
}

.hf-external-access {
  header.hf-toolbar {
    display: none;
  }
  aside.md-drawer {
    display: none;
  }
  nav.hf-drawer {
    display: none;
  }
  #main-desktop {
    margin: 0;
  }
}

// Compatibility for TipTap rich text editor
.ashtml-span {
  /* Basic editor styles */
  outline: none;

  :first-child {
    margin-top: 0;
  }

  /* List styles */
  ul,
  ol {
    margin: 1.25rem 1rem 1.25rem 0.4rem;
    padding: 0 1rem;

    li p {
      margin-top: 0.25em;
      margin-bottom: 0.25em;
    }
  }

  /* Heading styles */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.5rem;
    line-height: 1.1;
    text-wrap: pretty;
  }

  h1,
  h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  h4,
  h5,
  h6 {
    font-size: 1rem;
  }

  /* Code and preformatted text styles */
  code {
    padding: 0.25em 0.3em;

    font-size: 0.85rem;
    color: black;

    background-color: #efe8fa;
    border-radius: 0.4rem;
  }

  pre {
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;

    font-family: monospace;
    color: white;

    background: black;
    border-radius: 0.5rem;

    code {
      padding: 0;
      font-size: 0.8rem;
      color: inherit;
      background: none;
    }
  }

  blockquote {
    margin: 1rem 0;
    padding-left: 1rem;
    border-left: 3px solid #e0e0e0;
  }

  hr {
    margin: 2rem 0;
    border: none;
    border-top: 1px solid #e0e0e0;
  }

  img {
    border-radius: 8px;
  }

  /* Table styles */
  table {
    overflow: hidden;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: separate;

    width: 100%;
    margin: 0;

    font-family: inherit;

    border-radius: 8px;

    td,
    th {
      position: relative;

      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;

      vertical-align: middle;

      border: 0.5px solid rgb(0 0 0 / 21%);

      > * {
        margin-bottom: 0;
      }
    }

    th {
      text-align: left;
      background-color: rgb(0 0 0 / 12%);

      &:first-child {
        border-top-left-radius: 8px;
      }

      &:last-child {
        border-top-right-radius: 8px;
      }
    }

    tr:last-child {
      td:first-child {
        border-bottom-left-radius: 8px;
      }

      td:last-child {
        border-bottom-right-radius: 8px;
      }
    }

    .selectedCell::after {
      pointer-events: none;
      content: '';

      position: absolute;
      z-index: 2;
      inset: 0;

      background-color: rgb(64 202 161 / 21%);
    }

    .column-resize-handle {
      pointer-events: none;

      position: absolute;
      top: 0;
      right: -2.5px;
      bottom: -2px;

      width: 3px;

      background-color: #40caa1;
    }
  }
}

/*
  ==============================================================================================================
    Now add imports from our components and pages
  ==============================================================================================================
   */
@import '~components/mixins.scss';
@import '~components/components.scss';
@import '~pages/pages.scss';
@import '~pages/Host/components/components.scss';
