.hf-share {
  &-dialog {
    border-radius: 8px;
    width: 568px !important;
  }

  &-title {
    text-align: left;
  }

  &-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &-options {
      display: flex;
      align-items: center;
      column-gap: 16px;
      margin-bottom: 16px;

      @media only screen and (max-width: 500px) {
        flex-direction: column;
      }
    }

    &-language {
      display: flex;
      text-align: left;
      max-width: 100%;
      align-items: flex-end;
      column-gap: 16px;
    }
  }

  &-footer {
    padding: 0 24px 24px !important;
  }
}
