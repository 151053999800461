.hf-templateslist-item,
.hf-guidebookslist-item,
.hf-recommendationslist-item,
.hf-listingslist-item,
.hf-marketplacelist-item,
.hf-informationslist-item {
  .md-list-tile {
    position: relative;
  }
  .md-fake-btn.md-text--secondary,
  .md-fake-btn.md-text--theme-secondary {
    position: absolute;
    right: -8px;
    bottom: 6px;
  }
}

.hf-guidebooks > .md-grid {
  min-height: calc(100vh - 64px);
}

.hf-gb-status {
  display: flex;
  column-gap: 16px;
  justify-content: flex-end;
  align-items: center;

  .published {
    font-size: 16px;
    font-weight: 600;
    color: $md-green-500;
  }

  .hf-unpublish-btn {
    min-width: 108px;
  }

  .draft {
    font-size: 16px;
    font-weight: 600;
    color: $md-grey-500;
  }
}
.quill.hf-multilang-editor {
  .ql-card {
    background: no-repeat scroll 50% 50% transparent;
    background-image: url('/note_add-24px.svg');
    text-align: center;
  }
  .ql-pdf {
    background: no-repeat scroll 50% 50% transparent;
    background-image: url('/pdf-24px.svg');
    text-align: center;
  }
  .ql-var {
    background: no-repeat scroll 50% 50% transparent;
    background-image: url('/variable-24px.svg');
    text-align: center;
  }
}

.guidebook-step-closed {
  margin-bottom: 24px;
}
.wizard-modal {
  .wizard-section {
    p {
      font-size: 14px;
    }
    .rc-time-picker {
      padding: 4px;
      border: 1px solid $md-primary-color;
      border-radius: 8px;
      margin-bottom: 8px;
      input:focus-visible {
        outline-offset: 4px;
      }
    }
  }
  .wizard-heading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 18px;
  }
  .md-grid.md-grid--no-spacing > .md-cell.wizard-info-notice,
  .md-grid.md-grid--no-spacing > .md-cell.wizard-section {
    margin-bottom: 18px;
  }
  .wizard-info-notice {
    padding: 3px 6px;
    border: 1px solid $md-primary-color;
    border-radius: 8px;
    margin-bottom: 18px;
    background-color: $md-light-blue-100;
    color: $md-black-base;
    .md-icon {
      color: $md-primary-color;
      font-size: 24px;
      line-height: 20px;
      vertical-align: bottom;
    }
  }
}

.hf-float-upgrade {
  z-index: 1000;
  position: fixed;
  top: 7px;
  width: auto;
  right: 58px;
  font-size: 12px;
  padding: 6px 6px;
  height: auto;
}

.hf-step-actions {
  margin-left: -36px;
  width: calc(100% + 72px);
  padding-right: 36px;

  @media only screen and (max-width: 700px) {
    margin: 0;
    width: 100%;
    padding-right: 56px;
  }
}

.progress-container {
  border-color: #aaaaaa;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  height: 20px;
  .progress-complete {
    height: 100%;
    background-color: $md-secondary-color;
    border-radius: 6px;
  }
}

@media only screen and (min-width: 768px) {
  .hf-float-upgrade {
    font-size: 13px;
    padding: 8px 16px;
    right: 96px;
  }
}

@media only screen and (min-width: 1025px) {
  .hf-float-upgrade {
    top: 13px;
  }
}
