#card-templates-tabs {
  ul.hfTabs {
    padding-left: 0px !important;
    background-color: $md-white-base;
    height: 200px;
    > .md-tab {
      height: 200px;
      min-width: 125px;
    }
  }
  .md-btn--tab-overflow {
    bottom: 20px;
    z-index: 4;
  }
  .md-btn--tab-overflow-right {
    right: -18px;
  }
  .md-btn--tab-overflow-left {
    left: -18px;
  }
  .md-tab-indicator {
    display: none;
  }
  .md-btn--tab-overflow-right {
    color: rgba(0, 0, 0, 0.54);
  }
  .md-ink-container {
    background-position: top center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0px;
    margin-top: 4px;
  }
  .md-tab-label {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: rgba(0, 0, 0, 0.54);
  }
  .md-tab--icon {
    height: 120px;
    padding: 4px;
  }
  .md-tab--active {
    .md-tab-label {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  @media screen and (max-width: 550px) {
    .md-tab--icon {
      height: 80px;
      padding: 4px;
    }
    .md-tab-label {
      font-size: 8px;
      font-weight: 500;
      line-height: 8px;
    }
  }
  // tab colors
  .video-template-tab {
    .md-ink-container {
      background-image: url('https://storage.googleapis.com/hostfully-wp-1/video-card-template-5.png');
    }
    .md-icon {
      display: none;
    }
    &.md-tab--active {
      .md-ink-container {
        color: rgba(255, 255, 255, 0);
      }
      background-color: $md-amber-500;
    }
  }
  .photo-template-tab {
    .md-ink-container {
      background-image: url('https://storage.googleapis.com/hostfully-wp-1/photo-card-template-5.png');
    }
    .md-icon {
      display: none;
    }
    &.md-tab--active {
      .md-ink-container {
        color: rgba(255, 255, 255, 0);
      }
      background-color: $md-red-500;
    }
  }
  .text-template-tab {
    .md-ink-container {
      background-image: url('https://storage.googleapis.com/hostfully-wp-1/text-card-template-5.png');
    }
    .md-icon {
      display: none;
    }
    &.md-tab--active {
      .md-ink-container {
        color: rgba(255, 255, 255, 0);
      }
      background-color: $md-green-500;
    }
  }
  .icon-template-tab {
    .md-ink-container {
      background-image: url('https://storage.googleapis.com/hostfully-wp-1/icon-card-template-5.png');
    }
    .md-icon {
      display: none;
    }
    &.md-tab--active {
      .md-ink-container {
        color: rgba(255, 255, 255, 0);
      }
      background-color: $md-blue-500;
    }
  }
}
.recommendation-card {
  height: 180px;
}
.hf-clickable {
  cursor: pointer;
}
//apply md-react input styles to rc-time-picker input which is wrapped in a span.
input.rc-time-picker-input {
  //margin-top: 33px;
  border: none;
}
.rc-time-picker-input {
  padding: 0px 0px;
  height: auto;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.react-datepicker {
  font-family: $md-font-family;
  font-size: 14px;
  border: none;
}
.react-datepicker__month-container {
  border: none;
  box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
}
.react-datepicker__header {
  padding: 16px;
  background-color: $md-primary-color;
}
.react-datepicker__current-month,
.react-datepicker__day-name {
  padding: 2px;
  color: $md-white-base;
  font-size: 14px;
}
.react-datepicker__current-month {
  text-transform: uppercase;
}
.react-datepicker__day--selected {
  background-color: $md-primary-color;
}
.rc-time-picker-panel {
  border: none;
  box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
}
.rc-time-picker-panel-input-wrap,
.rc-time-picker-panel-input {
  padding: 8px;
  background-color: $md-primary-color;
  color: $md-white-base;
  font-size: 17px;
}
.rc-time-picker-panel-inner {
  font-size: 14px;
  font-family: $md-font-family;
  border: none;
  border-radius: 0px;
}
.rc-time-picker-panel-select {
  font-size: 14px;
}
li.rc-time-picker-panel-select-option-selected {
  background: $md-primary-color;
  color: $md-white-base;
}
.rc-time-picker-panel-select li:hover {
  background-color: $md-primary-color;
}
