.hf-print-inactive {
  position: relative;
}
.hf-print-inactive .hf-print-active:after {
  content: ' ';
  z-index: 2;
  display: block;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  cursor: not-allowed;
}
.md-media-overlay button.hf-print-activate,
.md-media-overlay button.hf-print-activate {
  color: $md-primary-color;
}
.hf-print-activate {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 65px);
  z-index: 2;
}
