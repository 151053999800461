.hf-alert {
  padding: 20px 24px;
  border-radius: 8px;
  border: 1px solid $md-grey-700;
  box-shadow: none;

  &-success {
    background-color: $md-green-50;
    border-color: $md-green-200;
  }

  &-info {
    background-color: $md-blue-50;
    border-color: $md-blue-200;
  }

  &-warning {
    background-color: $md-yellow-50;
    border-color: $md-yellow-200;
  }

  &-error {
    background-color: $md-red-50;
    border-color: $md-red-200;
  }

  &-message {
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  &-description {
    font-size: 14px;
  }
}
