.landing-page {
  .lp-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: blurred 1s cubic-bezier(0.23, 1, 0.32, 1) both;
    left: 50%;
    top: 50%;
    width: 70%;
    max-width: 400px;
    transform: translate(-50%, -50%);
    position: absolute;
    z-index: 99;
    filter: blur(0);

    .lp-info {
      background-color: rgba(255, 255, 255, 0.65);
      border-radius: 20px;
      padding: 20px 40px;
      margin: 0 0 1.5rem;
      font-size: 1.0825rem;
      font-weight: 700;
      text-align: center;

      i.md-icon.material-icons {
        font-size: inherit;
        color: inherit;
        line-height: inherit;
        vertical-align: bottom;
      }

      .lp-header {
        font-size: 2.25rem;
        line-height: 2.25rem;
        margin: 0 0 0.75rem;
      }
      .lp-subheader {
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-weight: 500;
        margin: 0 0 0.75rem;
      }
      .lp-logo {
        margin: 0 0 0.75rem;

        img.lp-logo-img {
          object-fit: contain;
          width: 100%;
          max-width: 100%;
          height: 110px;
        }
      }
      .lp-contact {
        margin: 0 0 0.75rem;

        .lp-email a {
          cursor: pointer;
          text-decoration: none;
          outline: none;
        }
        .lp-phone a {
          cursor: pointer;
          text-decoration: none;
          outline: none;
        }
      }
      .lp-address {
        cursor: pointer;
        margin: 0 0 0.75rem;
      }
    }
    .lp-button {
      border-radius: 20px;
      height: auto;
      font-size: 1.5rem;
      text-transform: none;
    }
  }
  .lp-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 50;
    background-color: rgb(0, 0, 0);
    opacity: 0.25;
  }
  .lp-locale-offset {
    top: 64px;
  }
  img.lp-bg {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
}
@media screen and (min-width: 1025px) {
  .landing-page {
    .lp-locale-offset {
      top: 48px;
    }
  }
}
