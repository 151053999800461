#main-tabs {
  ul.hfTabs {
    padding-left: 0px !important;
    background-color: $md-white-base;
    height: 95px;
    margin-bottom: -10px;
    z-index: 14;
  }
  .md-tabs-content {
    width: 100%;
  }
  .md-grid--hf-vertical-spacing > div {
    margin-bottom: 16px;
  }
  .md-btn--tab-overflow {
    bottom: 20px;
    z-index: 4;
  }
  .md-btn--tab-overflow-right {
    right: -18px;
  }
  .md-btn--tab-overflow-left {
    left: -18px;
  }
  .md-tab-indicator {
    display: none;
  }
  .md-btn--tab-overflow-right {
    color: rgba(0, 0, 0, 0.54);
  }
  .md-ink-container {
    background-position: top center;
    background-size: 58px 58px;
    background-repeat: no-repeat;
    padding: 24px;
    margin-top: 4px;
  }
  .md-tab-label {
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .md-tab--icon {
    height: 90px;
    padding: 4px;
  }
  #main-tabs-ul .hf-maintab .md-avatar {
    height: 50px;
    width: 50px;
    margin-bottom: 28px;
    border: 0;
    color: #ffffff;
  }
  // tab size
  @media screen and (min-width: 1025px) {
    .md-tab {
      min-width: 90px;
      max-width: 120px;
    }
    ul.hfTabs {
      height: 105px;
      margin-bottom: -10px;
    }
  }
  #main-tabs-ul .md-tab-label {
    color: rgba(0, 0, 0, 0.54);
  }
  #main-tabs-ul .md-tab--active {
    .md-tab-label {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  @media screen and (min-width: 551px) and (max-width: 1024px) {
    #main-tabs-ul .md-tab-label {
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 550px) {
    #main-tabs-ul .md-tab--icon {
      height: 80px;
      padding: 4px;
    }
    #main-tabs-ul .md-tab-label {
      font-size: 10px;
      font-weight: 500;
      line-height: 10px;
    }
    #main-tabs-ul .hf-maintab .md-avatar {
      height: 44px;
      width: 44px;
    }
  }
}
#hf-hidden-markers {
  margin-top: 10px;
  margin-bottom: -10px;
}
.recommendation-card {
  height: 180px;
}
.hf-rec-overlay-title h2 {
  line-height: 30px;
  max-height: 60px;
  overflow: hidden;
}
.hf-clickable {
  cursor: pointer;
}
//apply md-react input styles to rc-time-picker input which is wrapped in a span.
input.rc-time-picker-input {
  //margin-top: 33px;
  border: none;
}
.rc-time-picker-input {
  padding: 0px 0px;
  height: auto;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}
.react-datepicker {
  font-family: $md-font-family;
  font-size: 14px;
  border: none;
}
.react-datepicker__month-container {
  border: none;
  box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
}
.react-datepicker__header {
  padding: 16px;
  background-color: $md-primary-color;
}
.react-datepicker__current-month,
.react-datepicker__day-name {
  padding: 2px;
  color: $md-white-base;
  font-size: 14px;
}
.react-datepicker__current-month {
  text-transform: uppercase;
}
.react-datepicker__day--selected {
  background-color: $md-primary-color;
}
.rc-time-picker-panel {
  border: none;
  box-shadow:
    0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.4);
}
.rc-time-picker-panel-input-wrap,
.rc-time-picker-panel-input {
  padding: 8px;
  background-color: $md-primary-color;
  color: $md-white-base;
  font-size: 17px;
}
.rc-time-picker-panel-inner {
  font-size: 14px;
  font-family: $md-font-family;
  border: none;
  border-radius: 0px;
}
.rc-time-picker-panel-select {
  font-size: 14px;
}
li.rc-time-picker-panel-select-option-selected {
  background: $md-primary-color;
  color: $md-white-base;
}
.rc-time-picker-panel-select li:hover {
  background-color: $md-primary-color;
}
.hf-arrival-fab {
  background-color: $md-teal-a-400;
  color: $md-white-base;
  transition: all 1s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  -webkit-transition: all 2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
  top: 4px;
  right: 8px;
}
.hf-hidden-text {
  opacity: 0;
}
