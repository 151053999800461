.link-modal-outer {
  display: inline;
}
.link-modal-inner {
  display: inline;
  color: $md-primary-color;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
